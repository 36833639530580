import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { getFullName } from '../utility/stringUtility';
import { ToastManager } from './toast/toastManager';
import { AuthApi } from '../api/authApi';
type LoginType = {
  isAdmin: boolean;
};
const UserAccountButton = ({ isAdmin }: LoginType) => {
  const userContext = useContext(UserContext);
  const userMenu = useRef(null);
  const navigate = useNavigate();

  const userMenuItems: MenuItem[] = [
    {
      label: 'تسجيل الخروج',
      icon: 'pi pi-sign-out',
      command: () => logout(),
    },
  ];

  const logout = () => {
    AuthApi.logout().then((res) => {
      userContext.resetUser();
      if (isAdmin) {
        navigate('/admin-login');
        return;
      }
      navigate('/');
      ToastManager.showSuccess('تم تسجيل الخروج بنجاح');
    });
  };

  return (
    <div>
      {userContext.isLoggedIn() ? (
        <>
          <button
            type="button"
            className="flex items-center gap-2 px-5 text-white bg-primary rounded-xl"
            onClick={(event) => userMenu.current.toggle(event)}>
            <FaUserCircle className="inline text-sm" />
            <span
              className="text-lg font-main"
              style={{ maxWidth: 200, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {getFullName(userContext)}
            </span>
          </button>
          <Menu model={userMenuItems} ref={userMenu} popup />
        </>
      ) : (
        <Link to="/login" className="px-5 text-lg text-white flex bg-primary rounded-xl font-main">
          تسجيل الدخول
        </Link>
      )}
    </div>
  );
};

export default UserAccountButton;
