import dayjs from 'dayjs';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { GetQuestionsSummaryRequest } from '../../models/questionsApi';
import './dashboard.css';
import { UsersApi } from '../../api/usersApi';
import { GetUsersSummaryResponseItem, UsersSummaryTypeEnum } from '../../models/usersApi';
import EmptyStateImage from '../../assets/images/emptyState.svg';

import DateRangeDialog, { DialogDateRange } from './DateRangeDialog';
import { DateRangeI } from '../../pages/Reports';

const BoxUsersChart = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dateRange, setdateRange] = useState<DialogDateRange>({ from: null, to: null });

  useEffect(() => {
    loadData();
  }, []);

  const getDateStrings = (from: Date, to: Date): DateRangeI => {
    return {
      from: from ? dayjs(from).format('YYYY-MM-DD') : dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
      to: to ? dayjs(to).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
    };
  };

  const loadData = (request?: GetQuestionsSummaryRequest, setSubmitting?: (submitting: boolean) => void) => {
    const body: GetQuestionsSummaryRequest = request
      ? request
      : {
          searchParams: {
            from: dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
            to: dayjs(new Date()).format('YYYY-MM-DD'),
          },
        };

    setLoading(true);
    UsersApi.getUsersSummary(body).then(
      (res) => {
        setDataFromResponse(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const setDataFromResponse = (response: GetUsersSummaryResponseItem[]) => {
    const guestCount =
      Number.parseInt(response.find((item) => item.userTypeId == UsersSummaryTypeEnum.GEST)?.total) || 0;
    const userCount =
      Number.parseInt(response.find((item) => item.userTypeId == UsersSummaryTypeEnum.USER)?.total) || 0;
    const adminCount =
      Number.parseInt(response.find((item) => item.userTypeId == UsersSummaryTypeEnum.ADMIN)?.total) || 0;
    const IDKCount = Number.parseInt(response.find((item) => item.userTypeId == UsersSummaryTypeEnum.IDK)?.total) || 0;
    const supportCount =
      Number.parseInt(response.find((item) => item.userTypeId == UsersSummaryTypeEnum.SUPPORT)?.total) || 0;

    setData({
      labels: ['عدد الزوار', 'عدد المستخدمين', 'المستشارين', 'عدد مستخدمين الدعم الفني', 'عدد مدراء النظام'],
      datasets: [
        {
          label: null,
          backgroundColor: ['#99c3b1', '#27AE23', '#e6ff34', '#666666', '#4b5a08'],
          data: [guestCount, userCount, adminCount, supportCount, IDKCount],
        },
      ],
      total: userCount + adminCount + IDKCount + supportCount + guestCount,
    });
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '#495057',
        },
        position: 'left',
        maxWidth: 1000,
        align: 'left',
        rtl: true,
        lineWidth: 700,
        pointStyle: 'test',
        rotation: 700,
        strokeStyle: 'red',
      },
    },
  };

  return (
    <Card className="d-c">
      <div className="d-c-header">
        <div className="d-c-title">المستخدمين</div>
        <div className="d-c-date" onClick={() => {}}>
          <DateRangeDialog
            dateRange={dateRange}
            handleSubmit={(dateRange: DialogDateRange) => {
              setdateRange(dateRange);
              const request: GetQuestionsSummaryRequest = {
                searchParams: getDateStrings(dateRange.from, dateRange.to),
              };
              loadData(request);
            }}
          />
        </div>
      </div>

      {loading ? (
        <div className="center-chart-content ">
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          {!Number.isNaN(data?.total) && Number(data?.total) && Number(data?.total) > 0 ? (
            <div className="d-c-chart">
              <div className="flex flex-row justify-center card">
                <Chart
                  type="doughnut"
                  data={data}
                  options={chartOptions}
                  className="relative flex flex-row w-full lg:w-3/4 chart-container"
                />
              </div>
            </div>
          ) : (
            <div className="center-chart-content ">
              <img src={EmptyStateImage} alt="empty section" />
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default BoxUsersChart;
