import { createContext } from 'react';

interface LoginLayoutContextValue {
  title: string;
  image: string;
  setTitle?: (title: string) => void;
  setImage?: (title: string) => void;
}

const initialState: LoginLayoutContextValue = {
  title: '',
  image: '',
};

export const LoginLayoutContext = createContext(initialState);
