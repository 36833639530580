import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthApi } from '../../api/authApi';
import icon_email from '../../assets/icons/icon_email.svg';
import icon_password from '../../assets/icons/icon_password.svg';
import icon_user from '../../assets/icons/icon_user.svg';
import Button from '../../components/button/Button';
import LCCheckbox from '../../components/form/LCCheckbox';
import LcInput from '../../components/Input/LcInput';
import PasswordStrengthValidation, {
  PasswordStrengthValidationAttributes,
} from '../../components/PasswordStrengthValidation';
import TermsAndConditionsDialog, {
  TermsAndConditionsDialogAttributes,
} from '../../components/TermsAndConditionsDialog';
import { LoginLayoutContext } from '../../contexts/LoginLayoutContext';
import { PASSWORD_REGEX } from '../../models/passwordRegex';
import './RegisterForm.css';
import ReCAPTCHA from 'react-google-recaptcha';
const RegisterForm = ({
  setStep,
  setEmail,
}: {
  setStep: (step: string) => void;
  setEmail: (email: string) => void;
}) => {
  const loginLayoutContext = useContext(LoginLayoutContext);
  const termsRef = useRef<TermsAndConditionsDialogAttributes>(null);
  const passwordStrengthValidationRef = useRef<PasswordStrengthValidationAttributes>(null);
  const [capVal, setCapVal] = React.useState(null);
  useEffect(() => {
    loginLayoutContext.setTitle('إنشاء حساب جديد');
  }, []);

  const displayTermsAndConditions = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    termsRef.current.show();
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreedToTerms: false,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
        lastName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
        email: Yup.string().email('البريد الإلكتروني غير صحيح').required('إلزامي'),
        password: Yup.string().matches(PASSWORD_REGEX, 'يرجى تحقيق شروط كلمة المرور').required('إلزامي'),
        confirmPassword: Yup.string()
          .required('إلزامي')
          .oneOf([Yup.ref('password')], 'كلمات المرور غير متطابقة'),
        agreedToTerms: Yup.boolean().isTrue('إلزامي'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setEmail(values.email);
        AuthApi.register(values).then(
          () => {
            setSubmitting(false);
            setStep('otp');
          },
          (err) => {
            if (err.response?.data.statusCode === 406) {
              setStep('otp');
            }
            setSubmitting(false);
          }
        );
      }}>
      {({ isSubmitting, values, getFieldProps }) => (
        <Form className="r-form">
          <LcInput
            id="firstName"
            name="firstName"
            label="الاسم الأول"
            type="text"
            className="w-full"
            icon={icon_user}
          />

          <LcInput id="lastName" name="lastName" label="الاسم الأخير" type="text" className="w-full" icon={icon_user} />

          <LcInput
            id="email"
            name="email"
            label="البريد الإلكتروني"
            type="email"
            className="w-full"
            icon={icon_email}
          />

          <PasswordStrengthValidation password={values.password} ref={passwordStrengthValidationRef} />

          <LcInput
            id="password"
            name="password"
            label="كلمة المرور"
            type="password"
            className="w-full"
            icon={icon_password}
            onFocus={() => {
              passwordStrengthValidationRef.current.show();
            }}
            onBlur={(e: any) => {
              passwordStrengthValidationRef.current.hide();
              getFieldProps('password').onBlur(e);
            }}
          />

          <LcInput
            id="confirmPassword"
            name="confirmPassword"
            label="تأكيد كلمة المرور"
            type="password"
            className="w-full"
            icon={icon_password}
          />

          <LCCheckbox
            id="agreedToTerms"
            name="agreedToTerms"
            color="black"
            formik={true}
            label={
              <span>
                أنا أوافق على &nbsp;
                <span className="text-primary cursor-pointer hover:underline" onClick={displayTermsAndConditions}>
                  سياسة وشروط الاستخدام
                </span>
              </span>
            }
          />

          <TermsAndConditionsDialog ref={termsRef} />
          <ReCAPTCHA
            sitekey="6LfyvAkqAAAAAE_AcTYiG1FBQVpf6a1qKyx7fZ9H"
            onChange={(val: any) => {
              setCapVal(val);
            }}
          />
          <Button type="submit" disabled={capVal === null || isSubmitting}>
            إنشاء حساب جديد
          </Button>

          <span className="font-main">
            هل لديك حساب بالفعل؟ &nbsp;
            <Link to="/login" className="text-primary">
              سجل الدخول
            </Link>
          </span>
        </Form>
      )}
    </Formik>
  );
};

RegisterForm.propTypes = {
  setStep: PropTypes.func,
  setEmail: PropTypes.func,
};

export default RegisterForm;
