import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router';
import MainLayoutFooter from '../components/footer/MainLayoutFooter';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { UserContext } from '../contexts/UserContext';
import { UserTypes } from '../models/user';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/errorBoundary/ErrorBoundary';
import { useLocation, useNavigate } from 'react-router-dom';

const MainLayout = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const userContext = useContext(UserContext);

  const renderLayout = () => {
    // if (userContext.isUserType([UserTypes.ADMIN, UserTypes.SUPPORT])) {
    //   return <Navigate to="a" />;
    // } else {
    return (
      <div className="flex flex-col min-h-screen">
        <NavigationBar menuBarStyle="white"></NavigationBar>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            navigate(pathname);
          }}>
          <Outlet />
        </ErrorBoundary>
        <MainLayoutFooter />
      </div>
    );
    // }
  };

  return renderLayout();
};

export default MainLayout;
