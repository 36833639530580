import { Field } from 'formik';
import { Dialog } from 'primereact/dialog';
import React, { useImperativeHandle, useState } from 'react';
import LCButton from '../../components/form/LCButton';
import LCCalendar from '../../components/form/LCCalendar';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCDropdown from '../../components/form/LCDropdown';
import { SubCategory } from '../../models/questionsApi';

interface QuestionBankListMobileFilterDialogProps {
  values: any;
  handleChange: any;
  submitForm: () => Promise<any>;
  resetForm: () => Promise<any>;
  isSubmitting: boolean;
  categories: {
    id: number;
    name: string;
  }[];
  subCategories: SubCategory[];
  handlesubCategries: any;
}

export interface QuestionBankListMobileFilterDialogAttributes {
  show: () => void;
}

const QuestionBankListMobileFilterDialog = React.forwardRef<
  QuestionBankListMobileFilterDialogAttributes,
  QuestionBankListMobileFilterDialogProps
>(
  (
    { values, handleChange, submitForm, isSubmitting, categories, subCategories, handlesubCategries, resetForm },
    ref
  ) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        show: () => setVisible(true),
      };
    });

    return (
      <Dialog
        header={<span className="q-b-line"></span>}
        className="w-full font-main m-0 q-b-d"
        contentClassName="text-lg"
        resizable={false}
        icons={''}
        draggable={false}
        dismissableMask={true}
        position="bottom"
        visible={visible}
        onHide={() => setVisible(false)}>
        <div className="flex flex-col gap-4 mb-4">
          <h2 className="text-xl font-bold">تصفية النتائج</h2>
          <div className="flex flex-col gap-2">
            <label htmlFor="keyword">كلمات مفتاحية</label>
            <Field id="keyword" type="text" name="term" className=" p-3 rounded-lg bg-inputBackground shadow-md" />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="keyword">مجال الاستشارة</label>
            <LCDropdown
              options={categories.map((v, i) => {
                return { label: v.name, value: v.id };
              })}
              placeholder="اختر تصنيف الاستشارة"
              value={values.types}
              onChange={(e) => {
                handlesubCategries(e);
                submitForm();
              }}
              id="types"
              name="types"
              inputClassName="py-0 p-0 bg-inputBackground w-full border-0 shadow-md h-[50px]"
            />
            {subCategories.map((v, i) => (
              <LCCheckbox
                key={i}
                value={`${v?.id}`}
                name="types"
                id="types"
                formik={true}
                label={v?.name}
                onChange={(e) => {
                  handleChange(e);
                  submitForm();
                }}
                //disabled={!editMode}
              />
            ))}
          </div>
          <div>
            <div className="flex gap-2 items-center mt-5">
              <p className="text-lg mb-2">تاريخ الاستشارة</p>
            </div>
            <div className="grid grid-cols-2-form items-center gap-1">
              <p>من</p>
              <LCCalendar
                id="from"
                name="from"
                maxDate={new Date()}
                value={values.from}
                className="w-full"
                onChange={(e) => handleChange(e)}
              />
              <p>إلى</p>
              <LCCalendar
                id="to"
                name="to"
                maxDate={new Date()}
                minDate={new Date(values.from)}
                value={values.to}
                className="w-full"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="grid grid-cols-2-form items-center gap-1">
              <LCButton
                type="reset"
                label="اعادة تعيين التصفية"
                buttonStyle="primary"
                disabled={isSubmitting}
                onClick={() => resetForm()}
              />
            </div>
          </div>
          <LCButton
            type="submit"
            label="عرض النتائج"
            buttonStyle="primary"
            disabled={isSubmitting}
            onClick={() => {
              submitForm();
              setVisible(false);
            }}
          />
        </div>
      </Dialog>
    );
  }
);

export default QuestionBankListMobileFilterDialog;
