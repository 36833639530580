import { Card } from 'primereact/card';
import boardIcon from '../../assets/icons/icon_chat.svg';
import './cardAdvisoryBoard.css';

export interface CardAdvisoryBoardProps {
  name: string;
  position: string;
}

const CardAdvisoryBoard = ({ name, position }: CardAdvisoryBoardProps) => {
  return (
    <Card className="adb-card">
      <div className="adb-card-header">
        <div className="">
          <img className="adb-card-img" src={boardIcon} alt="icon" />
        </div>
        <h2 className="adb-card-title">{name}</h2>
      </div>
      <div className="adb-body">
        <p>{position}</p>
      </div>
    </Card>
  );
};

export default CardAdvisoryBoard;
