import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UsersApi } from '../../api/usersApi';
import icon_calendar from '../../assets/icons/icon_calendar.svg';
import icon_email_outlined from '../../assets/icons/icon_email_outlined.svg';
import icon_folders from '../../assets/icons/icon_folders.svg';
import icon_folder_check from '../../assets/icons/icon_folder_check.svg';
import icon_folder_x from '../../assets/icons/icon_folder_x.svg';
import icon_id_card from '../../assets/icons/icon_id_card.svg';
import icon_report_eye from '../../assets/icons/icon_report_eye.svg';
import icon_users from '../../assets/icons/icon_users.svg';
import DateSpan from '../../components/DateSpan';
import EnableDisableUser from '../../components/EnableDisableUser';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { GetUserResponse } from '../../models/usersApi';
import { getFormattedFullDate, getFullName } from '../../utility/stringUtility';
import DeleteUser from '../../components/DeleteUser';
import { set } from 'date-fns';

const UserDetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<GetUserResponse>(null);
  const layoutContext = useContext(AdminAndSupportLayoutContext);

  useEffect(() => {
    updateLayout();
    loadUser();
  }, []);

  const loadUser = () => {
    UsersApi.getUser({ id: Number.parseInt(id) }).then(
      (res) => {
        setUser(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateLayout = () => {
    layoutContext.setAll({
      breadcrumbsTitle: 'عرض المستخدم',
      breadcrumbs: [
        {
          label: 'إدارة المستخدمين',
          icon: <img src={icon_users} alt="Icon" />,
          linkTo: 'users',
        },
        {
          label: 'عرض المستخدم',
        },
      ],
    });
  };

  const mapIsEnabled = (isEnabled: boolean) => {
    if (isEnabled) return 'مفعل';
    else return 'معطل';
  };
  const mapIsDeleted = (isEnabled: boolean) => {
    if (isEnabled) return 'محذوف';
    else return 'نشط';
  };

  const setIsEnabled = (isEnabled: boolean) => {
    setUser({ ...user, isEnabled });
  };
  const setIsDeleted = (isDeleted: boolean) => {
    setUser({ ...user, isDeleted });
  };

  return (
    <div>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <>
          <div className="mt-4 lg:mt-0 grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex items-center gap-2 text-xl">
              <img src={icon_id_card} alt="Icon" className="h-6" />
              <div>المعرف</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.id}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_id_card} alt="Icon" className="h-6" />
              <div>الاسم</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{getFullName(user)}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_email_outlined} alt="Icon" className="h-6" />
              <div>البريد الإلكتروني</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.email}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_report_eye} alt="Icon" className="h-6" />
              <div>الحالة</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{mapIsEnabled(user.isEnabled)}</div>
            </div>
            <div className="flex items-center gap-2 text-xl">
              <img src={icon_report_eye} alt="Icon" className="h-6" />
              <div>حالة الحساب</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{mapIsDeleted(user.isDeleted)}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_folders} alt="Icon" className="h-6" />
              <div>عدد الاستشارات الكلية</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.numberOfQuestions}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_folder_check} alt="Icon" className="h-6" />
              <div>عدد الاستشارات المجاب عليها</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.numberOfAnsweredQuestions}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_folder_x} alt="Icon" className="h-6" />
              <div>عدد الاستشارات المعتذر منها</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.numberOfRejectedQuestions}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_calendar} alt="Icon" className="h-6" />
              <div>تاريخ التسجيل</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">
                <DateSpan date={user.verifiedAt} time={true} />
              </div>
            </div>
          </div>

          <EnableDisableUser
            id={Number.parseInt(id)}
            isEnabled={user.isEnabled}
            setIsEnabled={setIsEnabled}
            className="mt-4 float-left"
          />
          <div className="mt-10 mx-5"></div>
          <DeleteUser
            id={Number.parseInt(id)}
            isDeleted={user.isDeleted}
            setIsDeleted={setIsDeleted}
            className="mt-4 float-left"
          />
        </>
      )}
    </div>
  );
};

export default UserDetails;
