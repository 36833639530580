import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthApi } from '../api/authApi';
import icon_password from '../assets/icons/icon_password.svg';
import registration_image from '../assets/images/registration_image.png';
import Button from '../components/button/Button';
import LinkBtn from '../components/button/LinkBtn';
import LcInput from '../components/Input/LcInput';
import LCSimpleMessage from '../components/LCMessage';
import PasswordStrengthValidation, {
  PasswordStrengthValidationAttributes,
} from '../components/PasswordStrengthValidation';
import { LoginLayoutContext } from '../contexts/LoginLayoutContext';
import { UserContext } from '../contexts/UserContext';
import { PASSWORD_REGEX } from '../models/passwordRegex';

const ActivateAccount = () => {
  const userContext = useContext(UserContext);
  const loginLayoutContext = useContext(LoginLayoutContext);
  const [accountActivated, setAccountActivated] = useState(false);
  const passwordStrengthValidationRef = useRef<PasswordStrengthValidationAttributes>(null);

  let { token } = useParams();
  let [searchParams] = useSearchParams();
  let email = searchParams.get('email');

  useEffect(() => {
    loginLayoutContext.setTitle('تفعيل الحساب');
    loginLayoutContext.setImage(registration_image);
  }, []);

  return (
    <>
      {!accountActivated ? (
        <>
          <h1 className="text-xl font-main mb-3">أدخل كلمة المرور الجديدة {email && `للبريد الإلكتروني ${email}`}</h1>

          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={Yup.object({
              password: Yup.string().matches(PASSWORD_REGEX, 'يرجى تحقيق شروط كلمة المرور').required('إلزامي'),
              confirmPassword: Yup.string()
                .required('إلزامي')
                .oneOf([Yup.ref('password')], 'كلمات المرور غير متطابقة'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              AuthApi.activateUser({ password: values.password, token }).then(
                (res) => {
                  const data = res.data.data;
                  userContext.setUser(data);
                  setSubmitting(false);
                  setAccountActivated(true);
                },
                () => {
                  setSubmitting(false);
                }
              );
            }}>
            {({ isSubmitting, values, getFieldProps }) => (
              <Form className="flex flex-col items-start gap-4 w-fil lg:pl-24">
                <PasswordStrengthValidation password={values.password} ref={passwordStrengthValidationRef} />

                <LcInput
                  id="password"
                  name="password"
                  label="كلمة المرور الجديدة"
                  type="password"
                  className="w-full"
                  icon={icon_password}
                  onFocus={() => {
                    passwordStrengthValidationRef.current.show();
                  }}
                  onBlur={(e: any) => {
                    passwordStrengthValidationRef.current.hide();
                    getFieldProps('password').onBlur(e);
                  }}
                />

                <LcInput
                  id="confirmPassword"
                  name="confirmPassword"
                  label="تأكيد كلمة المرور"
                  type="password"
                  className="w-full"
                  icon={icon_password}
                />

                <Button type="submit" disabled={isSubmitting}>
                  متابعة
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <LCSimpleMessage type="success" label="فعّل الحساب بنجاح" className="mb-3" />
          <LinkBtn href="/">الصفحة الرئيسة</LinkBtn>
        </>
      )}
    </>
  );
};

export default ActivateAccount;
