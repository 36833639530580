import PropTypes from 'prop-types';
import React from 'react';

interface LCSimpleMessageProps {
  label: string;
  type: 'success';
  className?: string;
}

const LCSimpleMessage = ({ label, type, className }: LCSimpleMessageProps) => {
  const getIcon = (type: string) => {
    switch (type) {
      case 'success':
        return 'pi pi-check ml-2 text-green-500';
      default:
        return '';
    }
  };

  return (
    <p className={`text-xl font-main ${className}`}>
      <i className={getIcon(type)}></i>
      {label}
    </p>
  );
};

LCSimpleMessage.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['success']),
};

export default LCSimpleMessage;
