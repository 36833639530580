import { ProgressSpinner } from 'primereact/progressspinner';

interface EmptyPros {
  empty: any;
  children: any;
  message?: string;
}
interface LoaddingPros {
  status: boolean;
  children: any;
}
export const Loading = ({ status, children }: LoaddingPros) => {
  if (status) {
    return (
      <div style={{ textAlign: 'center' }}>
        <ProgressSpinner style={{ width: 50 }} />;
      </div>
    );
  }
  return children;
};

export const IsEmpty = ({ empty, children, message }: EmptyPros) => {
  if (empty) {
    return (
      <div
        style={{
          textAlign: 'center',
        }}>
        <span style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18, color: 'red' }}>
          {message || 'No Data To Display'}
        </span>
      </div>
    );
  }
  return children;
};

export function convertEnNumbersToArNumbers(num: number): string {
  // change num to string then split string to be array
  const enNumToStringArr = num.toString()?.split('');

  // get numbers in arabic
  const arNumArr = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

  // save converted result here
  let newArNum = '';

  for (let i of enNumToStringArr) {
    // get matched number from param array and save arabic value
    newArNum = newArNum + arNumArr[+i];
  }
  return newArNum;
}

export function convertArNumbersToEnNumbers(x: string): string {
  // get numbers in arabic

  let num = x.replace(/[^٠-٩]/g, '');

  // console.log({ numm });

  const arNumArr = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

  const enPlusArNumArr = [
    { en: '1', ar: '١' },
    { en: '2', ar: '٢' },
    { en: '3', ar: '٣' },
    { en: '4', ar: '٤' },
    { en: '5', ar: '٥' },
    { en: '6', ar: '٦' },
    { en: '7', ar: '٧' },
    { en: '8', ar: '٨' },
    { en: '9', ar: '٩' },
    { en: '0', ar: '٠' },
  ];

  // change num to string then split string to be array
  const numToArr = num.toString()?.split('');

  // numToArr

  if (!arNumArr.includes(numToArr.at(1))) return num;

  // save converted result here
  let newEnNum = '';
  // console.log({ newEnNum });

  for (let i of numToArr) {
    // console.log(arNumArr.includes(numToArr[numToArr.length - 1]));

    const item = enPlusArNumArr.find((n) => n.ar === i);

    // get matched number from param array and save arabic value
    newEnNum = newEnNum + item.en;
  }

  return newEnNum;
}
