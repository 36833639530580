import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';
import avatar_1 from '../../assets/images/avatar_1.png';
import { QuestionStatuses } from '../../models/questionsApi';
import RejectQuestionDialog, {
  RejectQuestionDialogAttributes,
} from '../../pages/questionsForAnswer/RejectQuestionDialog';
import { questionStatusMapper } from '../../utility/questionStatusMapper';
import { getFormattedDate } from '../../utility/stringUtility';
import LinkBtn from '../button/LinkBtn';
import ReassignQuestionPopup, { ReassignQuestionPopupAttributes } from '../ReassignQuestionPopup';

interface QuestionForAnswerCardProps {
  id: number;
  title: string;
  date: string;
  content: string;
  status: QuestionStatuses;
  link: string;
  isJudge: boolean;
  questionRejectedCallback: () => void;
  questionReassignedCallback: () => void;
}

const QuestionForAnswerCard = ({
  id,
  title,
  date,
  content,
  status,
  link,
  questionRejectedCallback,
  questionReassignedCallback,
  isJudge,
}: QuestionForAnswerCardProps) => {
  const actionMenu = useRef(null);
  const rejectQuestionDialogRef = useRef<RejectQuestionDialogAttributes>(null);
  const reassignQuestionPopupRef = useRef<ReassignQuestionPopupAttributes>(null);

  const actionMenuItems: MenuItem[] = [
    {
      label: 'الاعتذار من الإجابة',
      command: () => rejectQuestionDialogRef.current.show(),
    },
    {
      label: 'إعادة إسناد الاستشارة',
      command: (e) => reassignQuestionPopupRef.current.show(e.originalEvent as any),
    },
  ];

  const canTakeAction = (status: QuestionStatuses) => {
    return status === QuestionStatuses.ASSIGNED_TO_CONSULTANT || status === QuestionStatuses.RETURNED_TO_CONSULTANT;
  };

  const shouldShowStatus = (status: QuestionStatuses) => {
    return status === 'ASSIGNED_TO_CONSULTANT' || status === 'NEW' || status === 'RETURNED_TO_CONSULTANT';
  };

  return (
    <div className="c-container">
      <div className="c-header-intro">
        <div className="c-imgs">
          <div className="c-user-img">
            <img src={avatar_1} alt="user" width="66px" height="66px" className="w-[66px] h-[66px]" />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <h2 className="c-title-co">{title}</h2>
              {shouldShowStatus(status) && (
                <small className="bg-[#9ee8af52] text-primary font-main rounded-full text-sm text-center px-1 py-1">
                  {questionStatusMapper(status).name}
                </small>
              )}
            </div>
            <span className="c-date">{getFormattedDate(date)}</span>
          </div>
        </div>
        {canTakeAction(status) && (
          <div className="c-menu">
            <button className="c-menu-btn" onClick={(event) => actionMenu.current.toggle(event)}>
              <i className="pi pi-ellipsis-h"></i>
            </button>
            <Menu model={actionMenuItems} ref={actionMenu} popup />
            <RejectQuestionDialog
              ref={rejectQuestionDialogRef}
              id={id}
              isJudge={isJudge}
              questionRejectedCallback={questionRejectedCallback}
            />
            {/** TODO: Create a consultant reassign questions dialog to show a loading indicator */}
            <ReassignQuestionPopup
              ref={reassignQuestionPopupRef}
              id={id}
              type="answer"
              setLoading={() => {}}
              successCallback={questionReassignedCallback}
            />
          </div>
        )}
      </div>
      <div className="c-content">
        <p>{content}</p>
      </div>
      <div className="c-btn">
        <LinkBtn href={link}>عرض الاستشارة</LinkBtn>
      </div>
    </div>
  );
};

export default QuestionForAnswerCard;
