import React, { useImperativeHandle, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { PASSWORD_DIGITS_REGEX, PASSWORD_LETTERS_REGEX, PASSWORD_MIN_8_CHAR_REGEX } from '../models/passwordRegex';

interface PasswordStrengthValidationProps {
  password: string;
}

export interface PasswordStrengthValidationAttributes {
  show: () => void;
  hide: () => void;
}

const PasswordStrengthValidation = React.forwardRef<
  PasswordStrengthValidationAttributes,
  PasswordStrengthValidationProps
>(({ password }, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      show: () => setVisible(true),
      hide: () => setVisible(false),
    };
  });

  const isPasswordLengthValid = () => {
    return PASSWORD_MIN_8_CHAR_REGEX.test(password);
  };

  const isPasswordHasLetters = () => {
    return PASSWORD_LETTERS_REGEX.test(password);
  };

  const isPasswordHasDigits = () => {
    return PASSWORD_DIGITS_REGEX.test(password);
  };

  return (
    <>
      {visible && (
        <ul className="font-main border border-gray-400 rounded-lg p-2">
          <li>
            <p>
              {isPasswordLengthValid() && <FaCheck className="inline ml-2 text-green-600" />}
              {!isPasswordLengthValid() && <FaTimes className="inline ml-2 text-red-600" />}
              طول كلمة المرور 8 أو أكثر
            </p>
          </li>
          <li>
            <p>
              {isPasswordHasLetters() && <FaCheck className="inline ml-2 text-green-600" />}
              {!isPasswordHasLetters() && <FaTimes className="inline ml-2 text-red-600" />}
              حرف واحد على الأقل
            </p>
          </li>
          <li>
            <p>
              {isPasswordHasDigits() && <FaCheck className="inline ml-2 text-green-600" />}
              {!isPasswordHasDigits() && <FaTimes className="inline ml-2 text-red-600" />}
              رقم واحد على الأقل
            </p>
          </li>
        </ul>
      )}
    </>
  );
});

export default PasswordStrengthValidation;
