import { ListPermissionsResponseItem } from '../models/permissionsApi';
import { Permissions, UserTypes } from '../models/user';
import { ListUserTypesWithPermissionsResponseItem } from '../models/userTypesApi';

export const getPermissionId = (permissions: ListPermissionsResponseItem[], permissionName: Permissions) => {
  return permissions.find((p) => p.name === permissionName)?.id;
};

export const getPermissionsForUserType = (
  userTypesWithPermissions: ListUserTypesWithPermissionsResponseItem[],
  userType: UserTypes
) => userTypesWithPermissions.find((u) => u.name === userType).permissions || [];
