import { Form, Formik } from 'formik';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { PermissionsApi } from '../../api/permissionsApi';
import { UsersApi } from '../../api/usersApi';
import icon_email_outlined from '../../assets/icons/icon_email_outlined.svg';
import icon_folders from '../../assets/icons/icon_folders.svg';
import icon_folder_check from '../../assets/icons/icon_folder_check.svg';
import icon_folder_x from '../../assets/icons/icon_folder_x.svg';
import icon_id_card from '../../assets/icons/icon_id_card.svg';
import icon_report_eye from '../../assets/icons/icon_report_eye.svg';
import icon_tag_circle from '../../assets/icons/icon_tag_circle.svg';
import icon_star_card from '../../assets/icons/icon_star_card.svg';
import icon_user_circle from '../../assets/icons/icon_user_circle.svg';
import icon_user_gear from '../../assets/icons/icon_user_gear.svg';
import icon_user_paper from '../../assets/icons/icon_user_paper.svg';
import icon_users from '../../assets/icons/icon_users.svg';
import EnableDisableReceivingQuestions from '../../components/EnableDisableReceivingQuestions';
import EnableDisableUser from '../../components/EnableDisableUser';
import LCButton from '../../components/form/LCButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCDropdown from '../../components/form/LCDropdown';
import LCInputTextRounded from '../../components/form/LCInputTextRounded';
import { ToastManager } from '../../components/toast/toastManager';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { UserContext } from '../../contexts/UserContext';
import { ListPermissionsResponseItem } from '../../models/permissionsApi';
import { Permission, Permissions } from '../../models/user';
import { GetConsultantUserResponse, UpdatePermissionsRequest } from '../../models/usersApi';
import { getPermissionId } from '../../utility/permissionsUtility';
import { getFullName } from '../../utility/stringUtility';
import './CreateConsultantUser.css';
import EditConsultantPermissions from '../technicalSupportUsers/EditTechnicalSupportUser';

const ConsultantUserDetails = () => {
  let { id } = useParams();
  const layoutContext = useContext(AdminAndSupportLayoutContext);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<GetConsultantUserResponse>(null);
  const [permissionsList, setPermissionsList] = useState<ListPermissionsResponseItem[]>(null);
  const [editMode, setEditMode] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);

  useEffect(() => {
    updateLayout();
    loadUser();
  }, []);

  const loadUser = () => {
    Promise.all([UsersApi.getConsultantUser({ id: Number.parseInt(id) }), PermissionsApi.listPermissions()]).then(
      (res) => {
        setUser(res[0].data.data);
        setPermissionsList(res[1].data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateLayout = () => {
    layoutContext.setAll({
      breadcrumbsTitle: 'عرض المستخدم',
      breadcrumbs: [
        {
          label: 'إدارة المستشارين',
          icon: <img src={icon_user_paper} alt="Icon" />,
          linkTo: 'consultant-users',
        },
        {
          label: 'عرض المستخدم',
        },
      ],
    });
  };

  const mapIsEnabled = (isEnabled: boolean) => {
    if (isEnabled) return 'مفعل';
    else return 'معطل';
  };

  const mapType = (permissions: Permission[]) => {
    if (permissions.find((p) => p.name === Permissions.JUDGE_QUESTIONS && p.UserPermission.isEnabled)) {
      return 'مستشار محكم';
    } else {
      return 'مستشار';
    }
  };

  const mapTypeForForm = (permissions: Permission[]) => {
    if (permissions.find((p) => p.name === Permissions.JUDGE_QUESTIONS && p.UserPermission.isEnabled)) {
      return 'consultantAndReviewer';
    } else {
      return 'consultant';
    }
  };

  const updatePermissionsCallback = (enabledPermissionIds: number[]) => {
    user.permissions.forEach(
      (p) => (p.UserPermission.isEnabled = enabledPermissionIds.find((enabledPId) => enabledPId === p.id) != null)
    );
  };

  const setIsEnabled = (isEnabled: boolean) => {
    setUser({ ...user, isEnabled });
  };

  const setReceivingQuestionsEnabled = (receiveQuestionsEnabled: boolean) => {
    setUser({ ...user, receiveQuestionsEnabled });
  };

  const consultantTypeOptions = [
    {
      label: 'مستشار محكم',
      value: 'consultantAndReviewer',
    },
    {
      label: 'مستشار',
      value: 'consultant',
    },
  ];

  return (
    <div>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            position: user.position || '',
            type: mapTypeForForm(user.permissions),
            isTeam: user.isTeam,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            lastName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            position: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            type: Yup.string().required('إلزامي'),
          })}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            UsersApi.updateUser({ ...values, id: Number.parseInt(id) }).then(
              () => {
                setUser({ ...user, ...values });

                const judgeQuestionsPermissionId = getPermissionId(permissionsList, Permissions.JUDGE_QUESTIONS);

                const updatePermissionsRequest: UpdatePermissionsRequest = {
                  id: Number.parseInt(id),
                  disable: [],
                  enable: [],
                };

                if (values.type === 'consultantAndReviewer') {
                  updatePermissionsRequest.enable.push(judgeQuestionsPermissionId);
                } else {
                  updatePermissionsRequest.disable.push(judgeQuestionsPermissionId);
                }

                UsersApi.updatePermissions(updatePermissionsRequest).then(
                  (res) => {
                    setUser({ ...user, ...res.data.data });
                    setSubmitting(false);
                    setEditMode(false);
                  },
                  () => {
                    ToastManager.showError('لم يحدّث نوع المستشار', 'يرجى المحاولة مرة أخرى');
                    setFieldValue('type', mapTypeForForm(user.permissions));
                    setSubmitting(false);
                    setEditMode(false);
                  }
                );
              },
              () => {
                setSubmitting(false);
              }
            );
          }}>
          {({ isSubmitting, resetForm, values, getFieldProps }) => (
            <>
              <Form className="c-u-d-form">
                <div className="c-u-d-form-wrapper">
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_id_card} alt="Icon" className="h-6" />
                      <div>المعرف</div>
                    </div>
                    <div className="c-u-d-input-d">{user.id}</div>
                  </div>
                  {!editMode ? (
                    <div className="c-u-d-input">
                      <div className="c-u-d-inner">
                        <img src={icon_id_card} alt="Icon" className="h-6" />
                        <div>الاسم</div>
                      </div>
                      <div className="c-u-d-input-d">{getFullName(user)}</div>
                    </div>
                  ) : (
                    <div className="space-y-2">
                      <div className="c-u-d-input">
                        <div className="c-u-d-inner">
                          <img src={icon_id_card} alt="Icon" className="h-6" />
                          <label htmlFor="firstName">الاسم الأول</label>
                        </div>
                        <LCInputTextRounded
                          id="firstName"
                          name="firstName"
                          type="text"
                          inputClassName="w-full lg:w-auto"
                          containerClassName="grow"
                        />
                      </div>
                      <div className="c-u-d-input">
                        <div className="c-u-d-inner">
                          <img src={icon_id_card} alt="Icon" className="h-6" />
                          <label htmlFor="lastName">الاسم الأخير</label>
                        </div>
                        <LCInputTextRounded
                          id="lastName"
                          name="lastName"
                          type="text"
                          inputClassName="w-full lg:w-auto"
                          containerClassName="grow"
                        />
                      </div>
                    </div>
                  )}
                  {!editMode ? (
                    <div className="c-u-d-input">
                      <div className="c-u-d-inner">
                        <img src={icon_user_gear} alt="Icon" className="h-6" />
                        <div>المنصب</div>
                      </div>
                      <div className="c-u-d-input-d">{user.position}</div>
                    </div>
                  ) : (
                    <div className="c-u-d-input">
                      <div className="c-u-d-inner">
                        <img src={icon_user_gear} alt="Icon" className="h-6" />
                        <label htmlFor="position">المنصب</label>
                      </div>
                      <LCInputTextRounded
                        id="position"
                        name="position"
                        type="text"
                        inputClassName="w-full lg:w-auto"
                        containerClassName="grow"
                      />
                    </div>
                  )}
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_email_outlined} alt="Icon" className="h-6" />
                      <div>البريد الإلكتروني</div>
                    </div>
                    <div className="c-u-d-input-d">{user.email}</div>
                  </div>
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_report_eye} alt="Icon" className="h-6" />
                      <div>الحالة</div>
                    </div>
                    <div className="c-u-d-input-d">
                      {mapIsEnabled(user.isEnabled)}
                      {!user.isVerified && ' (لم يقم المستخدم بتفعيل الحساب)'}
                    </div>
                  </div>
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_users} alt="Icon" className="h-6" />
                      <div>الفريق العلمي</div>
                    </div>
                    <LCCheckbox name="isTeam" id="isTeam" formik={true} disabled={!editMode} />
                  </div>
                  {!editMode ? (
                    <div className="c-u-d-input">
                      <div className="c-u-d-inner">
                        <img src={icon_tag_circle} alt="Icon" className="h-6" />
                        <div>النوع</div>
                      </div>
                      <div className="c-u-d-input-d">{mapType(user.permissions)}</div>
                    </div>
                  ) : (
                    <div className="c-u-d-input">
                      <div className="c-u-d-inner">
                        <img src={icon_tag_circle} alt="Icon" className="h-6" />
                        <label htmlFor="type">النوع</label>
                      </div>
                      <LCDropdown
                        id="type"
                        name="type"
                        containerClassName="grow"
                        inputClassName="w-full lg:w-auto"
                        value={values.type}
                        options={consultantTypeOptions}
                        onChange={getFieldProps('type').onChange}
                        placeholder="اختر النوع"
                      />
                    </div>
                  )}
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_user_circle} alt="Icon" className="h-6" />
                      <div>استقبال الاستشارات</div>
                    </div>
                    <div className="c-u-d-input-d">{mapIsEnabled(user.receiveQuestionsEnabled)}</div>
                  </div>
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_folders} alt="Icon" className="h-6" />
                      <div>عدد الاستشارات المسندة المعلقة</div>
                    </div>
                    <div className="c-u-d-input-d">{user.numberOfPendingAssignedQuestions}</div>
                  </div>
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_folders} alt="Icon" className="h-6" />
                      <div>عدد الاستشارات الكلية</div>
                    </div>
                    <div className="c-u-d-input-d">{user.numberOfAssignedQuestions}</div>
                  </div>
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_folder_check} alt="Icon" className="h-6" />
                      <div>عدد الإجابات</div>
                    </div>
                    <div className="c-u-d-input-d">{user.numberOfAnswers}</div>
                  </div>
                  <div className="c-u-d-input">
                    <div className="c-u-d-inner">
                      <img src={icon_folder_x} alt="Icon" className="h-6" />
                      <div>عدد الاعتذارات</div>
                    </div>
                    <div className="c-u-d-input-d">{user.numberOfRejections}</div>
                  </div>
                </div>

                <div className="flex items-start gap-2 mt-4 text-xl">
                  <img src={icon_star_card} alt="Icon" className="h-6" />
                  <div>الصلاحيات</div>
                  <ul className="list-none pr-0 my-0">
                    {user.permissions.map((p) => (
                      <li key={p.id}>
                        <i
                          className={`ml-2 pi ${
                            p.UserPermission.isEnabled ? 'pi-check text-green-500' : 'pi-times text-pink-600'
                          }`}></i>
                        <span>{p.arName}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                {editMode ? (
                  <div className="flex justify-end gap-2">
                    <LCButton
                      label="حفظ"
                      type="submit"
                      buttonStyle="roundedStyle1"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    />
                    <LCButton
                      label="إلغاء"
                      type="button"
                      buttonStyle="roundedStyle1Outlined"
                      onClick={() => {
                        resetForm();
                        setEditMode(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col lg:flex-row lg:justify-end gap-4 lg:gap-2 my-4 lg:my-0">
                    <EnableDisableUser
                      id={Number.parseInt(id)}
                      isEnabled={user.isEnabled}
                      setIsEnabled={setIsEnabled}
                    />

                    <EnableDisableReceivingQuestions
                      id={Number.parseInt(id)}
                      receivingQuestionsEnabled={user.receiveQuestionsEnabled}
                      setReceivingQuestionsEnabled={setReceivingQuestionsEnabled}
                    />

                    {userContext.hasPermission(Permissions.UPDATE_USER_INFO) && (
                      <LCButton
                        label="تعديل"
                        type="button"
                        buttonStyle="roundedStyle1"
                        onClick={() => setEditMode(true)}
                      />
                    )}

                    {userContext.hasPermission(Permissions.MODIFY_PERMISSIONS) && (
                      <>
                        <LCButton
                          label="تعديل الصلاحيات"
                          type="button"
                          buttonStyle="roundedStyle1"
                          onClick={() => setEditDialogVisible(true)}
                        />
                        <EditConsultantPermissions
                          visible={editDialogVisible}
                          setVisible={setEditDialogVisible}
                          userId={Number.parseInt(id)}
                          permissions={user.permissions.map((p) => ({
                            id: p.id,
                            label: p.arName,
                            selected: p.UserPermission.isEnabled,
                          }))}
                          userUpdatedCallback={updatePermissionsCallback}
                        />
                      </>
                    )}
                  </div>
                )}
              </Form>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ConsultantUserDetails;
