import { Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import * as Yup from 'yup';
import { QuestionsApi } from '../../api/questionsApi';
import { UsersApi } from '../../api/usersApi';
import LCButton from '../../components/form/LCButton';
import LCDropdown from '../../components/form/LCDropdown';
import LCSimpleMessage from '../../components/LCMessage';
import { QuestionStatuses } from '../../models/questionsApi';
import {
  ListConsultantsToReassignAnswerResponseItem,
  ListConsultantsToReassignReviewResponseItem,
} from '../../models/usersApi';
import { getFullName } from '../../utility/stringUtility';
import './ReassignQuestionDialog.css';

export interface ReassignQuestionDialogAttributes {
  show: () => void;
}
interface ReassignQuestionDialogProps {
  questionId: number;
  questionStatus: QuestionStatuses;
  questionReassignedCallback: () => void;
}

const ReassignQuestionDialog = React.forwardRef<ReassignQuestionDialogAttributes, ReassignQuestionDialogProps>(
  ({ questionId, questionStatus, questionReassignedCallback }, ref) => {
    const [visible, setVisible] = useState(false);
    const [questionReassigned, setQuestionReassigned] = useState(false);

    const [answerConsultantsLoading, setAnswerConsultantsLoading] = useState(false);
    const [answerConsultantsLoadingError, setAnswerConsultantsLoadingError] = useState(false);
    const [answerConsultantsList, setAnswerConsultantsList] =
      useState<ListConsultantsToReassignAnswerResponseItem[]>(null);

    const [reviewConsultantsLoading, setReviewConsultantsLoading] = useState(false);
    const [reviewConsultantsLoadingError, setReviewConsultantsLoadingError] = useState(false);
    const [reviewConsultantsList, setReviewConsultantsList] =
      useState<ListConsultantsToReassignReviewResponseItem[]>(null);

    useImperativeHandle(ref, () => {
      return {
        show: () => setVisible(true),
      };
    });

    useEffect(() => {
      setQuestionReassigned(false);

      if (!visible) return;
      if (isQuestionForAnswer()) loadConsultantsForAnswer();
      if (isQuestionForReview()) loadConsultantsForReview();
    }, [visible]);

    const loadConsultantsForAnswer = () => {
      if (answerConsultantsList || answerConsultantsLoading) return;

      setAnswerConsultantsLoading(true);
      setAnswerConsultantsLoadingError(false);
      UsersApi.listConsultantsToReassignAnswer().then(
        (res) => {
          setAnswerConsultantsList(res.data.data);
          setAnswerConsultantsLoading(false);
        },
        () => {
          setAnswerConsultantsLoading(false);
        }
      );
    };

    const loadConsultantsForReview = () => {
      if (reviewConsultantsList || reviewConsultantsLoading) return;

      setReviewConsultantsLoading(true);
      setReviewConsultantsLoadingError(false);
      UsersApi.listConsultantsToReassignReview().then(
        (res) => {
          setReviewConsultantsList(res.data.data);
          setReviewConsultantsLoading(false);
        },
        () => {
          setReviewConsultantsLoading(false);
        }
      );
    };

    const isQuestionForAnswer = () => {
      return questionStatus === QuestionStatuses.NEW || questionStatus === QuestionStatuses.ASSIGNED_TO_CONSULTANT;
    };

    const isQuestionForReview = () => {
      return questionStatus === QuestionStatuses.ANSWERED || questionStatus === QuestionStatuses.ASSIGNED_TO_JUDGE;
    };

    const shouldShowConsultantsList = () => {
      return (isQuestionForAnswer() && answerConsultantsList) || (isQuestionForReview() && reviewConsultantsList);
    };

    const shouldShowConsultantsLoading = () => {
      return (isQuestionForAnswer() && answerConsultantsLoading) || (isQuestionForReview() && reviewConsultantsLoading);
    };

    const shouldShowConsultantsError = () => {
      return (
        (isQuestionForAnswer() && answerConsultantsLoadingError) ||
        (isQuestionForReview() && reviewConsultantsLoadingError)
      );
    };

    const getDropdownOptions = () => {
      if (isQuestionForAnswer()) return answerConsultantsList.map((c) => ({ label: getFullName(c), value: c.id }));
      if (isQuestionForReview()) return reviewConsultantsList.map((c) => ({ label: getFullName(c), value: c.id }));
    };

    return (
      <Dialog
        header={
          <div className="flex w-3/5 gap-2">
            <h1 className="text-3xl font-bold">إعادة تعيين الاستشارة</h1>
            <div className="h-1 grow bg-black relative self-end bottom-3"></div>
          </div>
        }
        closable={true}
        className="m-5 md:w-1/2 font-main"
        contentClassName="text-lg"
        resizable={false}
        draggable={false}
        dismissableMask={true}
        visible={visible}
        onHide={() => setVisible(false)}>
        {!questionReassigned ? (
          <Formik
            initialValues={{ consultantId: '' }}
            validationSchema={Yup.object({
              consultantId: Yup.number().required('إلزامي'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              let api;
              if (isQuestionForAnswer()) api = QuestionsApi.assignAnswerToConsultant;
              if (isQuestionForReview()) api = QuestionsApi.assignReviewToConsultant;

              api({ questionId, userId: Number.parseInt(values.consultantId) }).then(
                () => {
                  setSubmitting(false);
                  setQuestionReassigned(true);
                  questionReassignedCallback();
                },
                () => {
                  setSubmitting(false);
                }
              );
            }}>
            {({ isSubmitting, values, getFieldProps }) => (
              <Form className="flex flex-col gap-6">
                <p>اختر المستشار الجديد الذي تود إعادة تعيين الاستشارة إليه</p>

                {shouldShowConsultantsList() && (
                  <LCDropdown
                    id="consultantId"
                    name="consultantId"
                    inputClassName="w-full"
                    value={values.consultantId}
                    options={getDropdownOptions()}
                    onChange={getFieldProps('consultantId').onChange}
                    placeholder="اختر المستشار"
                  />
                )}
                {shouldShowConsultantsLoading() && <Skeleton className="h-7" />}
                {shouldShowConsultantsError() && (
                  <p>حصل خطأ أثناء تحميل قائمة المستشارين، يرجى المحاولة مرة أخرى لاحقًا</p>
                )}

                <div className="flex flex-row-reverse gap-2">
                  <LCButton
                    label="إلغاء"
                    type="button"
                    buttonStyle="roundedStyle1Outlined"
                    onClick={() => setVisible(false)}
                  />
                  <LCButton
                    label="إعادة إسناد"
                    type="submit"
                    buttonStyle="roundedStyle1"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <LCSimpleMessage type="success" label="تمت إعادة تعيين الاستشارة بنجاح" />
            <LCButton
              label="إغلاق"
              type="button"
              buttonStyle="roundedStyle1Outlined"
              className="float-left"
              onClick={() => setVisible(false)}
            />
          </>
        )}
      </Dialog>
    );
  }
);

export default ReassignQuestionDialog;
