interface typeError {
  error: any;
  resetErrorBoundary: () => void;
}

export default function ErrorFallback({ error, resetErrorBoundary }: typeError) {
  return (
    <div className="bg-white p-4 flex flex-col gap-4 mt-8 lg:my-[100px] justify-center items-center h-full w-full">
      <div className="flex flex-row gap-2">
        <p className="text-lg">حصل خطا اثناء تحميل الصفحة </p>
      </div>

      <button className="w-1/3 lg:w-[150px] bg-primary text-white p-3 rounded-lg text-lg" onClick={resetErrorBoundary}>
        حاول مرة إخرى
      </button>
    </div>
  );
}
