import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { UsersApi } from '../api/usersApi';
import { UserContext } from '../contexts/UserContext';
import { Permissions } from '../models/user';
import { lcConfirmPopup } from '../utility/lcConfirmPopup';
import LCButton from './form/LCButton';

interface DeleteUsersProps {
  id: number;
  isDeleted: boolean;
  setIsDeleted: (isEnabled: boolean) => void;
  className?: string;
}

const DeleteUser = ({ id, isDeleted, setIsDeleted, className }: DeleteUsersProps) => {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const deletedUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من حذف هذا المستخدم؟', () => {
      setLoading(true);
      UsersApi.deleteUser({ id }).then(
        () => {
          setIsDeleted(true);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    });
  };

  if (userContext.hasPermission(Permissions.MODIFY_USER_STATUS))
    return (
      <>
        {!isDeleted && (
          <LCButton
            label="حذف المستخدم"
            type="button"
            buttonStyle="roundedStyle1"
            className={className}
            onClick={deletedUser}
            disabled={loading}
            loading={loading}
          />
        )}
      </>
    );
  else return <></>;
};

DeleteUser.propTypes = {
  id: PropTypes.number,
  isEnabled: PropTypes.bool,
  setIsEnabled: PropTypes.func,
};

export default DeleteUser;
