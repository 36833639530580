import react from 'react';
import { FaHashtag } from 'react-icons/fa';
interface tagsContentCardProps {
    tags: Array<{ name: string }>
}
const TagsContentCard = ({ tags }: tagsContentCardProps) => {
    return (
        <div className="grid grid-cols-1-form lg:grid-cols-2-form lg:gap-x-4 gap-y-2">
            <div className="flex items-center gap-2">
                <p className="text-xl font-bold">وسم الاستشارة </p>
                <FaHashtag className="hidden lg:inline-block" />
            </div>
            <div className="flex gap-2 col-start-1 items-center lg:col-start-2">
                <div className="grow">
                    <div
                        id="tags"
                        className="bg-[#f9f9f9] rounded-xl w-full flex flex-row p-4 font-bold text-primary"
                    >
                        {
                            tags.map((v, i) => <a href={`/bank?tag=${v.name}`}> <p className='p-2' key={i}>#{v.name}</p></a>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TagsContentCard;