import { ErrorMessage, useField } from 'formik';
import { InputText } from 'primereact/inputtext';
import './input.css';

const LcInput = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <div className={`filed ${props.className}`}>
      <span className={`p-float-label p-input-icon-right  ${props.className}`}>
        {props.icon ? (
          <i>
            <img src={props.icon} alt="icons" className="md:hidden pi" />
          </i>
        ) : (
          ''
        )}
        <InputText {...field} {...props} className={`f-input ${props.className}`} />
        <label
          htmlFor={props.id || props.name}
          className={`${
            props.icon ? 'right-10' : 'right-3'
          } md:right-3 left-auto text-black top-[8] md:top-[5] font-main`}>
          {label}
        </label>
      </span>
      <ErrorMessage name={props.name} className="f-error" component="span" />
    </div>
  );
};

export default LcInput;
