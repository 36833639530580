import { MdShare } from 'react-icons/md';
import { Link } from 'react-router-dom';
import icon_paper from '../assets/icons/icon_paper.svg';
import { convertNumsToArabic } from '../helpers/number_converter';
import { getFormattedDate } from '../utility/stringUtility';
import LCLinkButton from './form/LCLinkButton';
import ShareQuestionWrapper from './ShareQuestionWrapper';
import React from 'react';

export interface QuestionCardProps {
  id: number | string;
  title: string;
  questionDate: string;
  questionContent: string;
  consultantName: string;
  answerContent: string;
  linkTo: string;
  linkType: 'button' | 'text';
  tags?: Array<{ name: string }>;
}

const QuestionCard = ({
  id,
  title,
  questionDate,
  questionContent,
  consultantName,
  answerContent,
  linkTo,
  linkType,
  tags,
}: QuestionCardProps) => {
  const lines = answerContent.split('/n');

  return (
    <div className="grid grid-cols-2-min-max shadow-lg rounded-md py-3 pl-3 border-t border-slate-100 bg-white">
      <img src={icon_paper} alt="Icon" className="mx-3 h-6" />

      <div className="flex justify-between items-center">
        <p className="text-xl truncate">{title}</p>
        <ShareQuestionWrapper questionId={id}>
          <button type="button">
            <MdShare className="text-primaryDark" />
          </button>
        </ShareQuestionWrapper>
      </div>

      <div className="justify-self-end w-1/2 h-full border-r border-primaryLight border-dashed"></div>

      <div>
        <div className="flex justify-between items-center">
          <p className="text-gray-600 text-right" style={{ direction: 'ltr' }}>
            {getFormattedDate(questionDate)}
          </p>
          <p className="text-gray-600 text-right" style={{ direction: 'ltr' }}>
            رقم الاستشارة : {convertNumsToArabic(id)}
          </p>
        </div>
        <p className="border border-primaryLight rounded-md px-2 py-1 h-20 overflow-hidden">{questionContent}</p>
      </div>

      <div className="justify-self-end w-1/2 h-2/3 border-r border-b border-primaryLight border-dashed"></div>

      <p className="text-lg mt-3 mr-2">{consultantName}</p>

      <p className="border border-primaryLight rounded-md px-2 py-1 mr-2 col-start-2 w-11/12 h-20 overflow-hidden">
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
        {/*{answerContent?.split('\n').map((line, idx) => (
          <React.Fragment key={idx}>
            <p>{line}</p>
            {idx < answerContent?.split('\n').length - 1 && <br />}
          </React.Fragment>
        ))}*/}
      </p>
      <div className=""></div>

      <div dir="rtl" className=" w-full p-1">
        {tags?.map((item: any) => {
          return <span className="mx-1 text-blue-400">#{item?.name}</span>;
        })}
      </div>

      {linkType === 'button' && (
        <LCLinkButton
          label=" عرض الاستشارة"
          to={linkTo}
          buttonStyle="rounded"
          className="col-start-2 text-center w-5/6 mt-5"
        />
      )}

      {linkType === 'text' && (
        <Link to={linkTo} className="col-start-2 text-primary text-lg text-left hover:underline mt-3">
          المزيد...
        </Link>
      )}
    </div>
  );
};

export default QuestionCard;
