import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthApi } from '../api/authApi';
import icon_email from '../assets/icons/icon_email.svg';
import icon_password from '../assets/icons/icon_password.svg';
import login_image from '../assets/images/login_image.png';
import Button from '../components/button/Button';
import LcInput from '../components/Input/LcInput';
import { LoginLayoutContext } from '../contexts/LoginLayoutContext';
import { UserContext } from '../contexts/UserContext';
import RegisterOtp from './register/RegisterOtp';
import ReCAPTCHA from 'react-google-recaptcha';

type LoginType = {
  isAdmin: boolean;
};
const Login = ({ isAdmin }: LoginType) => {
  const loginLayoutContext = useContext(LoginLayoutContext);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [step, setStep] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [capVal, setCapVal] = React.useState(null);

  useEffect(() => {
    loginLayoutContext.setTitle('تسجيل الدخول');
    loginLayoutContext.setImage(login_image);
  }, []);

  useEffect(() => {
    if (step === 'success') {
      navigate('/');
    }
  }, [step]);

  if (step === 'otp') {
    return <RegisterOtp setStep={setStep} email={email} />;
  }
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('البريد الإلكتروني غير صحيح').required('إلزامي'),
        password: Yup.string().required('إلزامي'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setEmail(values.email);
        if (isAdmin) {
          AuthApi.adminLogin(values).then(
            (res) => {
              const data = res.data.data;
              // console.log(data)
              userContext.setUser(data);
              setSubmitting(false);
              navigate('/a');
            },
            () => {
              setSubmitting(false);
            }
          );
        } else {
          AuthApi.login(values).then(
            (res) => {
              const data = res.data.data;
              // console.log(data)
              userContext.setUser(data);
              setSubmitting(false);
              navigate('/');
            },
            (err) => {
              if (err.response?.data.statusCode === 406) {
                // navigate('');
                setStep('otp');
              }

              setSubmitting(false);
            }
          );
        }
      }}>
      {({ isSubmitting }) => (
        <Form className="flex flex-col items-start gap-4 w-fil lg:pl-24">
          <LcInput
            id="email"
            name="email"
            label="البريد الإلكتروني"
            type="email"
            className="w-full"
            icon={icon_email}
          />

          <LcInput
            id="password"
            name="password"
            label="كلمة المرور"
            type="password"
            className="w-full"
            icon={icon_password}
          />

          <Link to="/request-password-reset" className="text-primary font-main">
            هل نسيت كلمة المرور؟
          </Link>
          <ReCAPTCHA
            sitekey="6LfyvAkqAAAAAE_AcTYiG1FBQVpf6a1qKyx7fZ9H"
            onChange={(val: any) => {
              setCapVal(val);
            }}
          />
          <Button type="submit" disabled={capVal === null || isSubmitting}>
            تسجيل الدخول
          </Button>

          <span className="font-main">
            ليس لديك حساب؟ &nbsp;
            <Link to="/register" className="text-primary underline">
              إنشاء حساب
            </Link>
          </span>
          <span className="font-main">
            يمكنك طلب المشورة دون التسجيل: &nbsp;
            <Link to="/new-question" className="text-primary underline">
              اطلب مشورة
            </Link>
          </span>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
