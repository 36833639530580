import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router';
import MainLayoutFooter from '../components/footer/MainLayoutFooter';
import { UserContext } from '../contexts/UserContext';
import { UserTypes } from '../models/user';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/errorBoundary/ErrorBoundary';
import { useLocation, useNavigate } from 'react-router-dom';

const MainWithoutNavbarLayout = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();

  const renderLayout = () => {
    return (
      <div className="flex flex-col min-h-screen">
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            navigate(pathname);
          }}>
          <Outlet />
        </ErrorBoundary>
        <MainLayoutFooter />
      </div>
    );
  };

  return renderLayout();
};

export default MainWithoutNavbarLayout;
