import { useRef } from 'react';
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md';
import academy_colored_logo from '../../assets/images/academy_colored_logo.png';
import home_page_image_4 from '../../assets/images/home_page_image_4.jpeg';
// import PolicyDialog, { PolicyDialogAttributes } from '../policy';
import TermsAndConditionsDialog, { TermsAndConditionsDialogAttributes } from '../TermsAndConditionsDialog';
import './MainLayoutFooter.css';

const MainLayoutFooter = () => {
  const termsRef = useRef<TermsAndConditionsDialogAttributes>(null);
  // const policyRef = useRef<PolicyDialogAttributes>(null);

  return (
    <>
    <div style={{ backgroundImage: `url(${home_page_image_4})`, backgroundSize: 'cover' }} className="f-wrapper">
      <div className="f-container">
        <div className="footer-square">
          <a target={'_blank'} href="https://ksaa.gov.sa/"><img src={academy_colored_logo}
                                                                alt="King Salman Global Academy for Arabic Language" /></a>
          <div className="f-icons">
            {/* <FaInstagram /> */}
            <a href="https://twitter.com/KSGAFAL">
              <FaTwitter />
            </a>
            <a href="https://facebook.com/KSGAAL">
              <FaFacebookSquare />
            </a>
          </div>
        </div>

        <nav className="f-nav footer-square">
          <p className="f-nav-text">سياسات المنصة</p>
          <p className="cursor-pointer" onClick={() => termsRef.current.show()}>
            الشروط والأحكام
          </p>
          <TermsAndConditionsDialog ref={termsRef} />
          {/* <p className="cursor-pointer" onClick={() => policyRef.current.show()}>
              سياسة استخدام المنصة
            </p>
            <PolicyDialog ref={policyRef} /> */}
          <p className="f-nav-text seperated">تواصل معنا</p>
          <div className="flex items-center gap-2">
            <MdAlternateEmail className="text-primary" />
            <span>support-almustashar@ksaa.gov.sa</span>
          </div>
        </nav>

        <div className="footer-square">
          <div className="re-edited-box-icon">
            <a
              href={`https://raqmi.dga.gov.sa/platforms/platforms/d3df613c-3f94-4a8e-9345-08dcb132860e/platform-license`}>
              <img src={`https://raqmi.dga.gov.sa/PlatformsApi/api/Attachments/18fecfa4-1d59-49ec-bbe6-b1ce00e5baf0`} alt={`Digital Signature`}/>
            </a>
          </div>

        </div>

    </div>
</div>

  <div className="f-copy-right flex flex-row justify-between">
    <p className="text-xl">{new Date().getFullYear()}© الحقوق محفوظة لمجمع الملك سلمان العالمي للغة العربية </p>
    <p className="text-xl">  ولأي تنبيه أو ملحوظة أو رأي يمكن مراسلة المنصة
      support-almustashar@ksaa.gov.sa</p>
  </div>;
</>
)
  ;
};

export default MainLayoutFooter;
