import { BackendResponse } from '../models/backendResponse';
import {
  AnswerQuestionRequest,
  ApproveQuestionRequest,
  AssignAnswerToConsultantRequest,
  AssignReviewToConsultantRequest,
  CreateQuestionRequest,
  DownloadConsultantsReportRequest,
  DownloadQuestionsReportRequest,
  GetAnswersCountResponse,
  GetBankQuestionRequest,
  GetBankQuestionResponse,
  GetCategories,
  GetConsultantAnswersSummaryResponseItem,
  GetMyQuestionRequest,
  GetMyQuestionResponse,
  GetQuestionRequest,
  GetQuestionResponse,
  GetQuestionsCategoryCountItem,
  GetQuestionsCountResponse,
  GetQuestionsSummaryByConsultantRequest,
  GetQuestionsSummaryByConsultantResponseItem,
  GetQuestionsSummaryRequest,
  GetQuestionsSummaryResponseItem,
  GetReviewerReviewsSummaryResponseItem,
  ListLateAnswersRequets,
  ListLateAnswersResponseItem,
  ListMyQuestionsRequest,
  ListMyQuestionsResponseItem,
  ListQuestionsBankRequest,
  ListQuestionsBankResponseItem,
  ListQuestionsForAnswerRequest,
  ListQuestionsForAnswerResponseItem,
  ListQuestionsForReviewRequest,
  ListQuestionsForReviewResponseItem,
  ListQuestionsRequest,
  ListQuestionsResponseItem,
  ListQuestionsWithoutHashTagResponseItem,
  QuestionCategoryCountRequest,
  ReassignAnswerRequest,
  ReassignReviewRequest,
  RejectQuestionRequest,
  ReturnQuestionToConsultantRequest,
  UpdateQuestionVisibilityRequest,
} from '../models/questionsApi';
import { QuestionsCounter } from '../models/quetionsCounter';
import { ListLatestUsersRequest } from '../models/usersApi';
import { Axios } from './axios';

const baseUrl = '/questions';

const createQuestion = (data: CreateQuestionRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}`, data);
};

const listMyQuestions = (params: ListMyQuestionsRequest) => {
  return Axios.get<BackendResponse<ListMyQuestionsResponseItem[]>>(`${baseUrl}/my_questions`, { params });
};

const listQuestions = (params: ListQuestionsRequest) => {
  return Axios.get<BackendResponse<ListQuestionsResponseItem[]>>(`${baseUrl}`, { params });
};

const listQuestionsForAnswer = (params: ListQuestionsForAnswerRequest) => {
  return Axios.get<BackendResponse<ListQuestionsForAnswerResponseItem[]>>(`${baseUrl}/pending_answers`, { params });
};

const listQuestionsForWithoutHashtag = (params: ListQuestionsForAnswerRequest) => {
  return Axios.get<ListQuestionsWithoutHashTagResponseItem[]>(`${baseUrl}/getQuestionsWithoutHashtags`, {
    params,
  });
};

const updateHashtagForQuestionWithoutHashtags = ({ id, tags }: any) => {
  return Axios.put<BackendResponse<{}>>(`${baseUrl}/addHashtag/${id}`, { tags });
};

const listQuestionsForReview = (params: ListQuestionsForReviewRequest) => {
  return Axios.get<BackendResponse<ListQuestionsForReviewResponseItem[]>>(`${baseUrl}/pending_reviews`, { params });
};

const listQuestionsBank = (data: ListQuestionsBankRequest) => {
  return Axios.post<BackendResponse<ListQuestionsBankResponseItem[]>>(`${baseUrl}/bank`, data);
};

const getQuestion = ({ id }: GetQuestionRequest) => {
  return Axios.get<BackendResponse<GetQuestionResponse>>(`${baseUrl}/${id}`);
};
const getCategories = () => {
  return Axios.get<BackendResponse<GetCategories[]>>(`/category`);
};
const getBankQuestion = ({ id }: GetBankQuestionRequest) => {
  return Axios.get<BackendResponse<GetBankQuestionResponse>>(`${baseUrl}/bank/${id}`);
};

const getMyQuestion = ({ id }: GetMyQuestionRequest) => {
  return Axios.get<BackendResponse<GetMyQuestionResponse>>(`${baseUrl}/my_questions/${id}`);
};

const getQuestionsCount = () => {
  return Axios.get<BackendResponse<GetQuestionsCountResponse>>(`${baseUrl}/questions_count`);
};

const getAnswersCount = () => {
  return Axios.get<BackendResponse<GetAnswersCountResponse>>(`${baseUrl}/answers_count`);
};

const getQuestionsSummary = (data: GetQuestionsSummaryRequest) => {
  return Axios.post<BackendResponse<GetQuestionsSummaryResponseItem[]>>(`${baseUrl}/questions_summary`, data);
};

const getQuestionsSummaryByConsultant = (data: GetQuestionsSummaryByConsultantRequest) => {
  return Axios.post<BackendResponse<GetQuestionsSummaryByConsultantResponseItem[]>>(
    `${baseUrl}/questions_summary_by_consultant`,
    data
  );
};

const getQuestionsCategoryCount = (data: QuestionCategoryCountRequest) => {
  return Axios.post<GetQuestionsCategoryCountItem[]>(`${baseUrl}/categories/questions_sub_cat_count`, data);
};

const getConsultantAnswersSummary = () => {
  return Axios.post<BackendResponse<GetConsultantAnswersSummaryResponseItem[]>>(
    `${baseUrl}/consultant_answers_summary`
  );
};

const getReviewerReviewsSummary = () => {
  return Axios.post<BackendResponse<GetReviewerReviewsSummaryResponseItem[]>>(`${baseUrl}/judge_reviews_summary`);
};

const updateQuestionVisibility = ({ id, hidden }: UpdateQuestionVisibilityRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/visibility`, { hidden });
};

const answerQuestion = ({ id, content, type, isEasy, categoryId, title, tags }: AnswerQuestionRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/answer`, {
    content,
    type,
    isEasy,
    categoryId,
    tags,
    title,
  });
};

const rejectQuestion = ({ id, rejectionReason, rejectionReasonLabel, isJudge }: RejectQuestionRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/reject`, { rejectionReason, rejectionReasonLabel, isJudge });
};

const approveQuestion = ({ id }: ApproveQuestionRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/review`);
};

const returnQuestionToConsultant = ({
  id,
  comments,
  judgeAnswer,
  type,
  categoryId,
  changedByJudge,
}: ReturnQuestionToConsultantRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/return_to_consultant`, {
    comments,
    judgeAnswer,
    type,
    categoryId,
    changedByJudge,
  });
};

const reassignAnswer = ({ id }: ReassignAnswerRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/reassign`);
};

const reassignReview = ({ id }: ReassignReviewRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/reassign_review`);
};

const assignAnswerToConsultant = (data: AssignAnswerToConsultantRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/assign_to_consultant`, data);
};

const assignReviewToConsultant = (data: AssignReviewToConsultantRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/assign_to_judge`, data);
};

const downloadQuestionsReport = (data: DownloadQuestionsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/reports/question_statuses`, data, { responseType: 'blob' });
};

const questionsReport = (data: DownloadQuestionsReportRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/reports/question_statuses_data`, data);
};

const downloadConsultantsReport = (data?: DownloadConsultantsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/reports/questions_per_consultant`, data, { responseType: 'blob' });
};

const downloadConsultionsReport = (data?: DownloadConsultantsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/reports/question_report`, data, { responseType: 'blob' });
};
const downloadLateAnswerReport = (data?: DownloadConsultantsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/reports/late_answers/download`, { data }, { responseType: 'blob' });
};

const listLateAnswers = (data?: ListLateAnswersRequets) => {
  return Axios.post<ListLateAnswersResponseItem[]>(`${baseUrl}/reports/late_answers`, data);
};

const questionsCounter = () => {
  return Axios.post<BackendResponse<QuestionsCounter[]>>(`${baseUrl}/new_quetions_count`);
};

export const QuestionsApi = {
  createQuestion,
  listMyQuestions,
  listQuestions,
  listQuestionsForAnswer,
  listQuestionsForWithoutHashtag,
  updateHashtagForQuestionWithoutHashtags,
  listQuestionsForReview,
  listQuestionsBank,
  getQuestion,
  getBankQuestion,
  getMyQuestion,
  getQuestionsCount,
  getAnswersCount,
  getQuestionsSummary,
  getQuestionsSummaryByConsultant,
  getConsultantAnswersSummary,
  getReviewerReviewsSummary,
  updateQuestionVisibility,
  answerQuestion,
  rejectQuestion,
  approveQuestion,
  returnQuestionToConsultant,
  reassignAnswer,
  reassignReview,
  assignAnswerToConsultant,
  assignReviewToConsultant,
  downloadQuestionsReport,
  downloadConsultantsReport,
  questionsCounter,
  downloadConsultionsReport,
  getCategories,
  getQuestionsCategoryCount,
  questionsReport,
  downloadLateAnswerReport,
  listLateAnswers,
};
