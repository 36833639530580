import React, { useContext, useEffect, useState } from 'react';
import registration_image from '../../assets/images/registration_image.png';
import { LoginLayoutContext } from '../../contexts/LoginLayoutContext';
import RegisterForm from './RegisterForm';
import RegisterOtp from './RegisterOtp';
import RegisterSuccess from './RegisterSuccess';

const Register = () => {
  const loginLayoutContext = useContext(LoginLayoutContext);
  const [step, setStep] = useState('form');
  const [email, setEmail] = useState<string>(null);

  useEffect(() => {
    loginLayoutContext.setImage(registration_image);
  }, []);

  return (
    <>
      {
        {
          form: <RegisterForm setStep={setStep} setEmail={setEmail} />,
          otp: <RegisterOtp setStep={setStep} email={email} />,
          success: <RegisterSuccess />,
        }[step]
      }
    </>
  );
};

export default Register;
