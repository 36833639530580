import { Permission, UserTypes } from './user';

export const USER_LOCAL_STORAGE_KEY = 'user';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  //authToken: string;
  userType: {
    name: UserTypes;
  };
  permissions: Permission[];
  receiveQuestionsEnabled: boolean;
  position: string;
}

export interface UserContextValue extends User {
  setUser?: (value: User) => void;
  resetUser?: () => void;
  isLoggedIn?: () => boolean;
  isUserType?: (userType: UserTypes | UserTypes[]) => boolean;
  hasPermission?: (permissionName: string) => boolean;
}
