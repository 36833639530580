import { ErrorMessage } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import './LCDropdown.css';

interface LCDropdownProps {
  id?: string;
  name?: string;
  options: { label: string; value: any }[];
  placeholder?: string;
  containerClassName?: string;
  inputClassName?: string;
  withFloatingLabel?: boolean;
  floatingLabel?: string;
  value: any;
  onChange: (e: any) => void;
}

const LCDropdown = ({
  id,
  name,
  options,
  placeholder = '',
  value,
  onChange,
  containerClassName,
  inputClassName,
  withFloatingLabel,
  floatingLabel,
}: LCDropdownProps) => {
  return (
    <div className={containerClassName}>
      {withFloatingLabel ? (
        <span className="p-float-label">
          <Dropdown
            inputId={id}
            name={name}
            className={inputClassName}
            value={value}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
          />
          <label htmlFor={id || name} className="right-3 left-auto text-black">
            {floatingLabel}
          </label>
        </span>
      ) : (
        <Dropdown
          inputId={id}
          name={name}
          className={inputClassName}
          value={value}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      <ErrorMessage name={name} className="text-red-600 inline-block" component="span" />
    </div>
  );
};

export default LCDropdown;
