import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { QuestionsApi } from '../api/questionsApi';
import { ListQuestionsBankRequest, ListQuestionsBankResponseItem } from '../models/questionsApi';
import { getFullName } from '../utility/stringUtility';
import Carousel from './Carousel/Carousel';
import QuestionCard, { QuestionCardProps } from './QuestionCard';

const QuestionsSlider = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<ListQuestionsBankResponseItem[]>([]);

  useEffect(() => {
    loadQuestions();
  }, []);

  const loadQuestions = () => {
    setLoading(true);
    const body: ListQuestionsBankRequest = {
      limit: 10,
      searchParams: {
        term: '',
        from: null,
        to: null,
        types: [],
        categoryId: null,
      },
    };
    QuestionsApi.listQuestionsBank(body).then(
      (res) => {
        setQuestions(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const getConsultantFullName = (question: ListQuestionsBankResponseItem) => {
    // alert('');
    if (question.answers[0].user.isTeam)
      return getFullName({
        firstName: question.reviews[0] ? question.reviews[0].user.firstName : question.answers[0].user.firstName,
        lastName: question.reviews[0] ? question.reviews[0].user.lastName : question.answers[0].user.lastName,
      });
    else if (question.reviews[question.reviews.length - 1]?.changedByJudge) {
      return getFullName({
        firstName: question.reviews[0]?.user.firstName,
        lastName: question.reviews[0]?.user.lastName,
      });
    } else {
      return getFullName({
        firstName: question.answers[0].user.firstName,
        lastName: question.answers[0].user.lastName,
      });
    }
  };

  const getAnswerContent = (question: ListQuestionsBankResponseItem) => {
    if (question.answers.length > 0) return question.answers[0].adminContent || question.answers[0].content;
    else return '';
  };

  const getCarouselItems = (): QuestionCardProps[] => {
    return questions.slice(0, 10).map((question) => ({
      key: question.id,
      id: question.id,
      title: question.title,
      questionDate: question.createdAt,
      questionContent: question.content,
      consultantName: getConsultantFullName(question),
      answerContent: getAnswerContent(question),
      linkTo: `/bank/${question.id.toString()}`,
      linkType: 'text',
    }));
  };

  return loading ? (
    <ProgressSpinner />
  ) : (
    <Carousel items={getCarouselItems()} Component={QuestionCard} pagination={true} />
  );
};

export default QuestionsSlider;
