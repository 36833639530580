import React from 'react';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Carousel.css';

/**
 *  you can read more here https://swiperjs.com/react
 * you can see demo more her https://swiperjs.com/demos#manipulation
 */

interface CarouselProps {
  Component: React.FC<any>;
  items: any[];
  spaceBetween?: number;
  navigation?: boolean;
  pagination?: boolean;
}

const Carousel = ({ Component, items, spaceBetween = 10, navigation = false, pagination = false }: CarouselProps) => {
  const slideItems = items.map((item, index) => (
    <SwiperSlide key={index}>
      <Component {...item} />
    </SwiperSlide>
  ));

  return (
    <Swiper
      dir="rtl"
      modules={[Autoplay, Navigation, Pagination, A11y]}
      spaceBetween={spaceBetween}
      slidesPerView={1}
      navigation={navigation}
      pagination={pagination}
      autoplay={{
        delay: 7000,
        disableOnInteraction: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}>
      {slideItems}
    </Swiper>
  );
};

export default Carousel;
