import { useEffect, useState } from 'react';
import { QuestionsApi } from '../../../api/questionsApi';
import { QuestionStatuses } from '../../../models/questionsApi';
import { QuestionsCounter } from '../../../models/quetionsCounter';
import { convertEnNumbersToArNumbers } from '../../../utility';

const QuestionsCounters = ({ isJudg }: { isJudg: Boolean }) => {
  const [questionsCounter, setQuestionsCounter] = useState<QuestionsCounter[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadQuestions();
  }, []);

  const loadQuestions = () => {
    setLoading(true);
    QuestionsApi.questionsCounter().then(
      (res) => {
        setQuestionsCounter(res.data.data);
        //setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  // console.log(questionsCounter);

  return (
    <div className="md:flex flex-row justify-around w-96">
      {loading ? (
        <></>
      ) : (
        questionsCounter.map((v, i) => (
          <div
            key={i}
            className={`${
              !isJudg && v.status === QuestionStatuses.ANSWERED ? 'hidden' : ''
            } border-2 border-primary flex justify-between flex-row box-content bg-white p-4 h-8 w-32 border-1 rounded-md`}>
            <h2 className="self-center font-main text-black text-lg">
              {v.status === QuestionStatuses.ANSWERED
                ? 'استشارات مجابة بانتظار التحكيم'
                : 'استشارات جديد بانتظار الاسناد'}
            </h2>
            <p className="self-center text-black font-main text-2xl">{convertEnNumbersToArNumbers(v.count)}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default QuestionsCounters;
