import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { MdShare } from 'react-icons/md';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionsApi } from '../../api/questionsApi';
import icon_chat from '../../assets/icons/icon_chat.svg';
import ConsultantContentCard from '../../components/ConsultantContentCard';
import ShareQuestionWrapper from '../../components/ShareQuestionWrapper';
import UserContentCard from '../../components/UserContentCard';
import { GetMyQuestionResponse, QuestionStatuses } from '../../models/questionsApi';
import { appendQuestionPrefix } from '../../utility/stringUtility';
import './MyQuestion.css';

const MyQuestionDetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState<GetMyQuestionResponse>(null);

  useEffect(() => {
    QuestionsApi.getMyQuestion({ id: Number.parseInt(id) }).then(
      (res) => {
        setQuestion(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const shouldShowShareButton = (question: GetMyQuestionResponse) => {
    return question.status === QuestionStatuses.CERTIFIED && !question.isHidden;
  };

  const shouldShowAnswer = (question: GetMyQuestionResponse) => {
    return (
      question.answers?.length > 0 &&
      (question.status === QuestionStatuses.CERTIFIED || question.status === QuestionStatuses.REJECTED)
    );
  };
  const consnultantNameHandler = (qs: GetMyQuestionResponse) => {
    if (qs.answers[0]?.editeAnswer)
      return {
        firstName: qs.reviews[0]?.user.firstName,
        lastName: qs.reviews[0]?.user.lastName,
      };
    else if (qs.answers[0].user.isTeam)
      return {
        firstName: qs.reviews[0] ? qs.reviews[0].user.firstName : qs.answers[0].user.firstName,
        lastName: qs.reviews[0] ? qs.reviews[0].user.lastName : qs.answers[0].user.lastName,
      };
    else if (qs.reviews[qs.reviews.length - 1]?.changedByJudge)
      return {
        firstName: qs.reviews[0]?.user.firstName,
        lastName: qs.reviews[0]?.user.lastName,
      };
    else {
      return {
        firstName: qs.answers[0].user.firstName,
        lastName: qs.answers[0].user.lastName,
      };
    }
  };
  const getAnswerConsultantContentCardContentDate = () => {
    if (question.status === QuestionStatuses.REJECTED) {
      return question.answers[0].updatedAt;
    } else {
      return question.answeredAt;
    }
  };
  // console.log({ question });

  return (
    <div className="my-q-d ">
      <div className="my-q-d-breadcrumb ">
        <p className="my-q-d-breadcrumb-p">عرض الاستشارة</p>
        <div className="my-q-d-breadcrumb-items "></div>

        <div className="flex items-center gap-1">
          <div className="flex items-center">
            <img src={icon_chat} alt="Icon" className="inline ml-2 h-6" />
            <Link to=".." className="text-xl">
              استشاراتي
            </Link>
          </div>

          <HiOutlineChevronRight className="text-lg" />

          <p className="text-xl">عرض الاستشارة</p>
        </div>
      </div>

      {loading ? (
        <ProgressSpinner />
      ) : (
        <div className="my-q-d-card">
          <div className="flex items-center justify-between">
            <h1 className="my-q-d-title">{question.title}</h1>

            {shouldShowShareButton(question) && (
              <ShareQuestionWrapper questionId={id}>
                <button className="my-q-d-btn-share">
                  <MdShare className="text-primary" />
                  <span className="hidden lg:flex">مشاركة الاستشارة</span>
                </button>
              </ShareQuestionWrapper>
            )}
          </div>

          <UserContentCard
            date={question.createdAt}
            content={appendQuestionPrefix(question.content)}
            className="mt-5"
            backgroundColorClass="bg-[#eeeeee]"
          />

          {shouldShowAnswer(question) && (
            <ConsultantContentCard
              consultantName={consnultantNameHandler(question)}
              contentDate={getAnswerConsultantContentCardContentDate()}
              type="answer"
              status={question.answers[0].status}
              content={
                question.answers[0].adminContent || question.reviews[0]?.judgeAnswer || question.answers[0].content
              }
              rejectionReason={question.rejectionReason}
              className="mt-4"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MyQuestionDetails;
