import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UsersApi } from '../../api/usersApi';
import icon_calendar from '../../assets/icons/icon_calendar.svg';
import icon_email_outlined from '../../assets/icons/icon_email_outlined.svg';
import icon_id_card from '../../assets/icons/icon_id_card.svg';
import icon_report_eye from '../../assets/icons/icon_report_eye.svg';
import icon_star_card from '../../assets/icons/icon_star_card.svg';
import icon_support from '../../assets/icons/icon_support.svg';
import DateSpan from '../../components/DateSpan';
import EnableDisableUser from '../../components/EnableDisableUser';
import LCButton from '../../components/form/LCButton';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { UserContext } from '../../contexts/UserContext';
import { Permissions } from '../../models/user';
import { GetTechnicalSupportUserResponse } from '../../models/usersApi';
import { getFullName } from '../../utility/stringUtility';
import EditTechnicalSupportUser from './EditTechnicalSupportUser';

const TechnicalSupportUserDetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<GetTechnicalSupportUserResponse>(null);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const userContext = useContext(UserContext);
  const layoutContext = useContext(AdminAndSupportLayoutContext);

  useEffect(() => {
    updateLayout();
    loadUser();
  }, []);

  const loadUser = () => {
    UsersApi.getTechnicalSupportUser({ id: Number.parseInt(id) }).then(
      (res) => {
        setUser(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateLayout = () => {
    layoutContext.setAll({
      breadcrumbsTitle: 'عرض المستخدم',
      breadcrumbs: [
        {
          label: 'إدارة الدعم الفني',
          icon: <img src={icon_support} alt="Icon" />,
          linkTo: 'technical-support-users',
        },
        {
          label: 'عرض المستخدم',
        },
      ],
    });
  };

  const mapIsEnabled = (isEnabled: boolean) => {
    if (isEnabled) return 'مفعل';
    else return 'معطل';
  };

  const setIsEnabled = (isEnabled: boolean) => {
    setUser({ ...user, isEnabled });
  };

  const userUpdatedCallback = (enabledPermissionIds: number[]) => {
    user.permissions.forEach(
      (p) => (p.UserPermission.isEnabled = enabledPermissionIds.find((enabledPId) => enabledPId === p.id) != null)
    );
  };

  return (
    <div>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4 lg:mt-0">
            <div className="flex items-center gap-2 text-xl">
              <img src={icon_id_card} alt="Icon" className="h-6" />
              <div>المعرف</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.id}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_id_card} alt="Icon" className="h-6" />
              <div>الاسم</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{getFullName(user)}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_email_outlined} alt="Icon" className="h-6" />
              <div>البريد الإلكتروني</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">{user.email}</div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_report_eye} alt="Icon" className="h-6" />
              <div>الحالة</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">
                {mapIsEnabled(user.isEnabled)}
                {!user.isVerified && ' (لم يقم المستخدم بتفعيل الحساب)'}
              </div>
            </div>

            <div className="flex items-center gap-2 text-xl">
              <img src={icon_calendar} alt="Icon" className="h-6" />
              <div>تاريخ التسجيل</div>
              <div className="grow bg-adminMenuBackground rounded-lg px-2">
                <DateSpan date={user.createdAt} time={true} />
              </div>
            </div>
          </div>

          <div className="flex items-start gap-2 mt-4 text-xl">
            <img src={icon_star_card} alt="Icon" className="h-6" />
            <div>الصلاحيات</div>
            <ul className="list-none pr-0 my-0">
              {user.permissions.map((p) => (
                <li key={p.id}>
                  <i
                    className={`ml-2 pi ${
                      p.UserPermission.isEnabled ? 'pi-check text-green-500' : 'pi-times text-pink-600'
                    }`}></i>
                  <span>{p.arName}</span>
                </li>
              ))}
            </ul>
          </div>

          {userContext.id.toString() !== id && (
            <div className="flex flex-col md:flex-row lg:justify-end gap-2 mt-4">
              <EnableDisableUser id={Number.parseInt(id)} isEnabled={user.isEnabled} setIsEnabled={setIsEnabled} />

              {userContext.hasPermission(Permissions.MODIFY_PERMISSIONS) && (
                <>
                  <LCButton
                    label="تعديل الصلاحيات"
                    type="button"
                    buttonStyle="roundedStyle1"
                    onClick={() => setEditDialogVisible(true)}
                  />
                  <EditTechnicalSupportUser
                    visible={editDialogVisible}
                    setVisible={setEditDialogVisible}
                    userId={Number.parseInt(id)}
                    permissions={user.permissions.map((p) => ({
                      id: p.id,
                      label: p.arName,
                      selected: p.UserPermission.isEnabled,
                    }))}
                    userUpdatedCallback={userUpdatedCallback}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TechnicalSupportUserDetails;
