import { Form, Formik } from 'formik';
import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { useContext, useEffect, useState } from 'react';
import { QuestionsApi } from '../../api/questionsApi';
import icon_folders from '../../assets/icons/icon_folders.svg';
import DateSpan from '../../components/DateSpan';
import LCDropdown from '../../components/form/LCDropdown';
import LCLinkButton from '../../components/form/LCLinkButton';
import LCSearch from '../../components/form/LCSearch';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { PAGINATION_LIMIT, paginatorTemplate } from '../../models/pagination';
import {
  AnswerStatuses,
  ListQuestionsRequest,
  ListQuestionsResponseItem,
  QuestionStatuses,
} from '../../models/questionsApi';
import { questionStatusMapper } from '../../utility/questionStatusMapper';
import { getFullName } from '../../utility/stringUtility';
import { saveAs } from 'file-saver';

import { Dropdown } from 'primereact/dropdown';
import LCButton from '../../components/form/LCButton';
import { FaFileExcel, FaRegFileExcel } from 'react-icons/fa';
import { convertNumsToArabic } from '../../helpers/number_converter';
import dayjs from 'dayjs';
const QuestionsList = () => {
  const layoutContext = useContext(AdminAndSupportLayoutContext);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [questions, setQuestions] = useState<ListQuestionsResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListQuestionsRequest>({
    searchParam: '',
    offset: 0,
    limit: PAGINATION_LIMIT,
    status: null,
    sort: 'ASC',
  });

  const statusRowFilterTemplate = () => {
    return (
      <Dropdown
        id="consultantId"
        name="consultantId"
        // inputClassName="w-full"
        value={'values.consultantId'}
        options={[
          { label: 'الكل', value: null },
          { label: 'جديدة', value: 'NEW' },
          { label: 'مجابة', value: 'ANSWERED' },
          { label: 'مرفوضة', value: 'REJECTED' },
          { label: 'معاد اسنادها', value: 'REASSIGNED' },
          { label: 'مسندة الى محكم', value: 'ASSIGNED_TO_JUDGE' },
          { label: 'معتمدة', value: 'CERTIFIED' },
          { label: 'تم ارجاعها الى مستشار', value: 'RETURNED_TO_CONSULTANT' },
          { label: 'مسندة الى مستشار', value: 'ASSIGNED_TO_CONSULTANT' },
        ]}
        onChange={(v) =>
          setRequest({
            ...request,
            status: v.target.value,
          })
        }
        placeholder="اختر الحالة"
      />
    );
  };
  useEffect(() => {
    layoutContext.setAll({ title: 'إدارة الاستشارات', titleIcon: <img src={icon_folders} alt="Icon" /> });
  }, []);

  useEffect(() => {
    const searchParam = localStorage.getItem('admin-question-list-search-state') || '';

    setRequest((old) => {
      return {
        ...old,
        searchParam,
      };
    });
  }, []);

  useEffect(() => {
    loadQuestions();
  }, [request]);

  const loadQuestions = () => {
    setLoading(true);
    QuestionsApi.listQuestions(request).then(
      (res) => {
        setQuestions(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const DownloadExcelSheet = async (setSubmitting: (submitting: boolean) => void) => {
    await QuestionsApi.downloadConsultionsReport().then(
      (res) => {
        saveAs(res.data, 'الاستشارات.xlsx');
        setSubmitting(false);
      },
      () => {
        setSubmitting(false);
      }
    );
  };
  const handleOnPage = (event: DataTablePFSEvent) => {
    setRequest({ ...request, offset: event.first, limit: event.rows });
  };

  const getConsultantName = (question: ListQuestionsResponseItem) => {
    // console.log(question);

    if (question.answers.length === 0) {
      return 'غير مسندة';
    } else {
      return getFullName(question?.answers[question?.answers.length - 1]?.user);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-center items-center lg:justify-between flex-col lg:flex-row gap-4">
        <LCSearch
          search={request.searchParam}
          loading={loading}
          onSubmit={(searchParam) => {
            localStorage.setItem('admin-question-list-search-state', searchParam);
            setRequest({ ...request, searchParam, offset: 0 });
          }}
        />
        <Formik
          initialValues={{ type: '', from: null, to: null }}
          onSubmit={(values, { setSubmitting }) => {
            DownloadExcelSheet(setSubmitting);
          }}>
          {({ isSubmitting, values, getFieldProps, handleChange }) => (
            <Form className="flex flex-col gap-5 lg:flex-row lg:items-center items-start">
              {statusRowFilterTemplate()}
              <LCButton
                label="تحميل"
                type="submit"
                icon={<FaRegFileExcel className="ml-2 " />}
                buttonStyle="roundedStyle2"
                disabled={isSubmitting}
                loading={isSubmitting}
                className="max-w-[140px]"
              />
            </Form>
          )}
        </Formik>
      </div>

      <DataTable
        value={questions}
        loading={loading}
        scrollable
        scrollDirection="horizontal"
        //removableSort
        className="lc-table"
        paginator
        stateStorage="session"
        stateKey="admin-question-list-session-state"
        filterDisplay="row"
        paginatorTemplate={paginatorTemplate}
        first={request.offset}
        rows={PAGINATION_LIMIT}
        tableStyle={{ width: '100%' }}
        lazy
        onSort={(e) => {
          console.log(e.sortOrder);
          setRequest((prev) => ({ ...prev, sort: e.sortOrder === 0 ? 'DESC' : 'ASC' }));
          setSortOrder(sortOrder === 0 ? 1 : 0);
        }}
        sortField={'time'}
        sortOrder={sortOrder}
        totalRecords={total}
        onPage={handleOnPage}>
        <Column header="المعرف" body={(rowData) => convertNumsToArabic(rowData.id)} />
        <Column
          header="عنوان الاستشارة"
          field="title"
          style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          body={(consultation) => consultation.title || 'لا يوجد عنوان'}
        />
        <Column
          header="المستشار"
          body={(rowData) => getConsultantName(rowData)}
          style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        />
        <Column
          header="ملاحظات"
          body={(rowData) => (
            <p className="text-red-500 font-bold">
              {!rowData?.answers[0]?.user?.isEnabled &&
              rowData?.answers[0]?.status == AnswerStatuses.ASSIGNED_TO_CONSULTANT
                ? 'تحتاج اعادة اسناد - المستشار معطل'
                : ''}
            </p>
          )}
          style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        />
        <Column
          header="الحالة"
          body={(rowData) => (
            <div
              style={{ backgroundColor: `${questionStatusMapper(rowData?.status)?.color}` }}
              className={`bg-${questionStatusMapper(rowData?.status)?.color} rounded-md p-2 text-white`}>
              {' '}
              {questionStatusMapper(rowData?.status)?.name}{' '}
            </div>
          )}
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
          style={{
            minWidth: '12rem',
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          field="time"
          header="وقت إجابة الاستشارة"
          body={(rowData) => (rowData?.answeredAt ? dayjs().locale('ar').to(rowData?.answeredAt) : 'غير مجابة')}
          sortable
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="التفاصيل"
          body={(rowData) => <LCLinkButton label="عرض" to={rowData?.id?.toString()} buttonStyle="rounded" />}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
      </DataTable>
    </div>
  );
};

export default QuestionsList;
