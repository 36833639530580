import homeImg from '../../assets/icons/admin/caticon.png';
import mangeImg from '../../assets/icons/admin/mangicon.png';
import reportImg from '../../assets/icons/admin/report.png';
import supportimg from '../../assets/icons/admin/support.png';
import mangeCon from '../../assets/icons/admin/usermange.png';
import mangeUsers from '../../assets/icons/admin/users.png';
import iconForAdminHomePage from '../../assets/icons/icon_for-admin-home-page.svg';
import { Permissions } from '../../models/user';

export interface SidebarDataInterface {
  title: string;
  path: string;
  imgSrc: string;
  linkEnd: boolean;
  permission?: Permissions;
  target?: string;
}

export const SidebarData: SidebarDataInterface[] = [
  {
    title: 'الصفحة الرئيسة',
    path: '/a',
    imgSrc: homeImg,
    linkEnd: true,
  },
  {
    title: 'إدارة الاستشارات',
    path: 'questions',
    imgSrc: mangeImg,
    linkEnd: false,
    permission: Permissions.LIST_QUESTIONS,
  },
  {
    title: 'إدارة المستشارين',
    path: 'consultant-users',
    imgSrc: mangeCon,
    linkEnd: false,
    permission: Permissions.LIST_CONSULTANTS,
  },
  {
    title: 'إدارة المستخدمين',
    path: 'users',
    imgSrc: mangeUsers,
    linkEnd: false,
    permission: Permissions.LIST_USERS,
  },
  {
    title: 'إدارة الدعم الفني',
    path: 'technical-support-users',
    imgSrc: supportimg,
    linkEnd: false,
    permission: Permissions.LIST_SUPPORTS,
  },
  {
    title: 'التقارير',
    path: 'reports',
    imgSrc: reportImg,
    linkEnd: false,
  },
  {
    title: 'عرض الموقع',
    path: '/',
    imgSrc: iconForAdminHomePage,
    linkEnd: false,
    target: '_blank',
  },
];
