import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import academy_colored_logo from '../../assets/images/academy_logo_inverted.png';
import academy_white_logo from '../../assets/images/almustashar_allughawi_white_logo.png';
import { UserContext } from '../../contexts/UserContext';
import { UserTypes } from '../../models/user';
import UserAccountButton from '../UserAccountButton';
import './navigationBar.css';

interface NavigationBarProps {
  menuBarStyle: 'transparent' | 'white';
}

const NavigationBar = ({ menuBarStyle }: NavigationBarProps) => {
  const userContext = useContext(UserContext);
  const [matches, setMatches] = useState(window.matchMedia('(max-width: 800px)').matches);
  const [homePageUrl, setHomePageUrl] = useState('/');

  useEffect(() => {
    window.matchMedia('(max-width: 800px)').addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    setHomePageUrl(userContext.isUserType(UserTypes.CONSULTANT) ? '/c' : '/');
  }, [userContext.userType]);

  const menuItemTemplate = (item: MenuItem, options: any) => {
    return (
      <>
        <NavLink
          className={({ isActive }) =>
            `font-header font-bold text-xl mx-3 ${textColor[menuBarStyle]} ${isActiveLink(isActive)}`
          }
          target={item.target}
          onClick={options.onClick}
          to={item.url}>
          {item.label}
        </NavLink>
      </>
    );
  };

  const isActiveLink = (isActive: boolean): string => {
    if (isActive) return 'border-r-4 border-primary lg:border-none lg:underline lg:underline-offset-8 lg:decoration-2';
    else return '';
  };

  const getItems = (): MenuItem[] => {
    let itemsArray = [
      {
        label: 'الرئيسة',
        url: homePageUrl,
        template: menuItemTemplate,
      },
      {
        label: 'هيئة المستشارين',
        url: '/consultants',
        template: menuItemTemplate,
      },
      {
        label: 'خزانة الاستشارات',
        url: '/bank',
        template: menuItemTemplate,
      },
    ];

    if (userContext.isLoggedIn() && userContext.isUserType(UserTypes.USER)) {
      itemsArray.push({
        label: 'استشاراتي',
        url: '/my-questions',
        template: menuItemTemplate,
      });
    }

    itemsArray.push({
      label: 'المكتبة الرقمية',
      url: '/library',
      template: menuItemTemplate,
    });
    if (
      (userContext.isLoggedIn() && userContext.isUserType(UserTypes.ADMIN)) ||
      userContext.isUserType(UserTypes.SUPPORT)
    ) {
      itemsArray.push({
        label: 'لوحة التحكم',
        url: '/a',
        template: menuItemTemplate,
      });
    }
    matches &&
      itemsArray.push({
        label: '',
        url: '',
        template: (item: MenuItem, options: any) => (
          <div className="flex py-4 px-8 justify-start lg:hidden">
            {' '}
            <UserAccountButton isAdmin={false} />
          </div>
        ),
      });

    return itemsArray;
  };

  const menuBarClassName = {
    transparent: 'nav-transparent rounded-none bg-transparent pt-5 text-white ',
    white: 'nav-white rounded-none bg-white shadow-md p-3 lg:px-3 lg:py-2',
  };

  const textColor = {
    transparent: ' lg:text-white',
    white: 'text-black',
  };

  const academyLogo = {
    transparent: academy_white_logo,
    white: academy_colored_logo,
  };

  return (
    <Menubar
      model={getItems()}
      start={
        <Link to={homePageUrl}>
          {
            // <div className='h-20 lg:h-20 lg:ml-20 border-2 border-white flex justify-center items-center p-2'>
            //   <h3 className='h-text font-header'>تشغيل تجريبي</h3>
            // </div>
            <img src={academyLogo[menuBarStyle]} className="h-10 lg:h-20 lg:ml-20" alt="المستشار اللغوي" />
          }
        </Link>
      }
      end={<UserAccountButton isAdmin={false} />}
      className={menuBarClassName[menuBarStyle]}
    />
  );
};

export default NavigationBar;
