import { Permission, UserTypes } from './user';

export enum UsersSummaryTypeEnum {
  GEST = '1',
  USER = '2',
  SUPPORT = '3',
  IDK = '4',
  ADMIN = '5',
}
export interface UserResponse {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  isEnabled: boolean;
  isVerified: boolean;
  receiveQuestionsEnabled: boolean;
  createdAt: string;
  permissions: Permission[];
}

export interface ListUsersRequest {
  searchParam?: string;
  offset?: number;
  limit?: number;
}

export interface ListUsersResponseItem {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  isDeleted: boolean;
}

export interface GetUserRequest {
  id: number;
}

export interface GetUserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  isDeleted: boolean;
  createdAt: string;
  verifiedAt?: string;
  numberOfQuestions: number;
  numberOfAnsweredQuestions: number;
  numberOfRejectedQuestions: number;
}

export interface ListTechnicalSupportUsersRequest {
  searchParam?: string;
  offset?: number;
  limit?: number;
}

export interface ListTechnicalSupportUsersResponseItem {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  isVerified: boolean;
}

export interface GetTechnicalSupportUserRequest {
  id: number;
}

export interface GetTechnicalSupportUserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  createdAt: string;
  isVerified: boolean;
  verifiedAt?: string;
  permissions: Permission[];
}

export interface CreateTechnicalSupportUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  enable: number[];
  disable: number[];
}

export interface CreateTechnicalSupportUserResponse {
  id: number;
}

export interface ListConsultantUsersRequest {
  searchParam?: string;
  offset?: number;
  limit?: number;
}

export interface ListConsultantUsersResponseItem {
  id: number;
  firstName: string;
  lastName: string;
  isEnabled: boolean;
  isVerified: boolean;
  receiveQuestionsEnabled: boolean;
  numberOfPendingAssignedQuestions: number;
  permissions: Permission[];
}

export interface GetConsultantUserRequest {
  id: number;
}

export interface GetConsultantUserResponse {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  isTeam: boolean;
  isEnabled: boolean;
  isVerified: boolean;
  receiveQuestionsEnabled: boolean;
  createdAt: string;
  permissions: Permission[];
  numberOfPendingAssignedQuestions: number;
  numberOfAssignedQuestions: number;
  numberOfAnswers: number;
  numberOfRejections: number;
}

export interface CreateConsultantUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  isTeam: boolean;
  position: string;
  enable: number[];
  disable: number[];
}

export interface CreateConsultantUserResponse {
  id: number;
}

export interface ListPublicConsultantsRequest {
  searchParam?: string;
  offset?: number;
  limit?: number;
}

export interface ListPublicConsultantsResponseItem {
  name: string;
  position: string;
}

export interface ListConsultantsToReassignAnswerResponseItem {
  id: number;
  firstName: string;
  lastName: string;
}

export interface ListConsultantsToReassignReviewResponseItem {
  id: number;
  firstName: string;
  lastName: string;
}

export interface GetUsersSummaryResponseItem {
  total: string;
  userTypeId: UsersSummaryTypeEnum;
}
export interface ListLatestUsersRequest {
  from: string;
  to: string;
  offset?: number;
  limit?: number;
}
export interface GetUsersSummaryRequest {
  searchParams: {
    from: string;
    to: string;
  };
}
export interface ListLatestUsersResponseItem {
  id: number;
  firstName: string;
  lastName: string;
  createdAt: string;
  email: string;
  userType: {
    name: UserTypes;
  };
}

export interface GetUsersCountResponse {
  usersCount: number;
}

export interface EnableUserRequest {
  id: number;
}
export interface DeleteUserRequest {
  id: number;
}

export interface DisableUserRequest {
  id: number;
}

export interface EnableReceivingQuestionsRequest {
  id: number;
}

export interface DisableReceivingQuestionsRequest {
  id: number;
}

export interface UpdateUserRequest {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
}

export interface UpdatePermissionsRequest {
  id: number;
  enable: number[];
  disable: number[];
}

export interface UpdatePermissionsResponse extends UserResponse {}
