import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useRef } from 'react';
import { ToastManager } from './toast/toastManager';

interface ShareQuestionWrapperProps {
  children: JSX.Element | JSX.Element[];
  questionId: number | string;
}

const ShareQuestionWrapper = ({ children, questionId }: ShareQuestionWrapperProps) => {
  const shareQuestionMenu = useRef(null);

  const getTwitterShareLink = () => {
    return `https://twitter.com/share?url=${encodeURIComponent(getUrl())}`;
  };

  const getFacebookShareLink = () => {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(getUrl())}`;
  };

  const getWhatsappShareLink = () => {
    return `https://wa.me/?text=${encodeURIComponent(getUrl())}`;
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(getUrl());
    ToastManager.showSuccess('تم نسخ الرابط');
  };

  const getUrl = () => `https://${window.location.host}/bank/${questionId}`;

  const shareQuestionMenuItems: MenuItem[] = [
    {
      label: 'مشاركة عبر تويتر',
      icon: 'pi pi-twitter',
      url: getTwitterShareLink(),
    },
    {
      label: 'مشاركة عبر فيس بوك',
      icon: 'pi pi-facebook',
      url: getFacebookShareLink(),
    },
    {
      label: 'مشاركة عبر واتساب',
      icon: 'pi pi-whatsapp',
      url: getWhatsappShareLink(),
    },
    {
      label: 'نسخ الرابط',
      icon: 'pi pi-clone',
      url: '',
      command: copyShareLink,
    },
  ];

  return (
    <>
      <Menu model={shareQuestionMenuItems} ref={shareQuestionMenu} popup />
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: (event: React.MouseEvent) => shareQuestionMenu.current.toggle(event),
        })
      )}
    </>
  );
};

export default ShareQuestionWrapper;
