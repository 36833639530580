import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { UsersApi } from '../api/usersApi';
import { lcConfirmPopup } from '../utility/lcConfirmPopup';
import LCButton from './form/LCButton';

const EnableDisableReceivingQuestions = ({
  id,
  receivingQuestionsEnabled,
  setReceivingQuestionsEnabled,
}: {
  id: number;
  receivingQuestionsEnabled: boolean;
  setReceivingQuestionsEnabled: (receivingQuestionsEnabled: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const enableReceivingQuestions = (event: React.MouseEvent<HTMLButtonElement>) => {
    lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من تفعيل استقبال الاستشارات لهذا المستشار؟', () => {
      setLoading(true);
      UsersApi.enableReceivingQuestions({ id }).then(
        () => {
          setReceivingQuestionsEnabled(true);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    });
  };

  const disableReceivingQuestions = (event: React.MouseEvent<HTMLButtonElement>) => {
    lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من تعطيل استقبال الاستشارات لهذا المستشار؟', () => {
      setLoading(true);
      UsersApi.disableReceivingQuestions({ id }).then(
        () => {
          setReceivingQuestionsEnabled(false);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    });
  };

  return (
    <>
      {receivingQuestionsEnabled && (
        <LCButton
          label="إلغاء تفعيل استقبال الاستشارات"
          type="button"
          buttonStyle="roundedStyle1"
          onClick={disableReceivingQuestions}
          disabled={loading}
          loading={loading}
        />
      )}
      {!receivingQuestionsEnabled && (
        <LCButton
          label="تفعيل استقبال الاستشارات"
          type="button"
          buttonStyle="roundedStyle1"
          onClick={enableReceivingQuestions}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
};

EnableDisableReceivingQuestions.propTypes = {
  id: PropTypes.number,
  receivingQuestionsEnabled: PropTypes.bool,
  setReceivingQuestionsEnabled: PropTypes.func,
};

export default EnableDisableReceivingQuestions;
