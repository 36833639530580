import { Dialog } from 'primereact/dialog';
import React, { useImperativeHandle, useState } from 'react';
import './TermsAndConditionsDialog.css';

export interface TermsAndConditionsDialogAttributes {
  show: () => void;
}

const TermsAndConditionsDialog = React.forwardRef<TermsAndConditionsDialogAttributes, {}>(({}, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      show: () => setVisible(true),
    };
  });

  return (
    <Dialog
      header="شروط الاستخدام (منصة المستشار اللغوي)"
      className="m-5 md:w-1/2 font-main"
      contentClassName="text-lg"
      resizable={false}
      draggable={false}
      dismissableMask={true}
      visible={visible}
      onHide={() => setVisible(false)}>
      <h2 className="font-bold text-xl">أمن المعلومات الشخصية</h2>

      <p className="text-justify mt-2">
        أعدت وثيقة الخصوصية وسرية المعلومات لمساعدة الزوار والمستخدمين على فهم طبيعة البيانات التي تُجمع من زيارات
        المنصة، وكيفية التعامل معها.{' '}
      </p>
      <p className="text-justify mt-2">
        تتخذ إدارة المنصة الإجراءات والتدابير المناسبة للمحافظة على المعلومات الشخصية المسجلة لديها على نحو آمن يضمن
        حمايتها من الفقدان، أو الدخول غير المصرح به، أو إساءة الاستخدام، أو التعديل والإفصاح غير المصرح بهما. ومن أهم
        التدابير المعمول بها في منصة المستشار اللغوي التابعة لمجمع الملك سلمان العالمي للغة العربية لحماية معلومات
        الزائر الشخصية:
      </p>
      <p className="text-justify mt-2">
        - استخدام الإجراءات والتدابير المشددة لحماية أمن المعلومات والتقنية؛ للوقاية من عمليات الاحتيال والدخول غير
        المصرح به إلى أنظمتنا.{' '}
      </p>
      <p className="text-justify mt-2">
        - التحديث الدوري المنتظم لإجراءات الحماية وضوابطها التي تفي بالمعايير القياسية أو تزيد عليها.
      </p>
      <p className="text-justify mt-2">- أنّ موظفينا مؤهلون ومدربون على احترام سرية المعلومات الشخصية لزائرينا.</p>

      <h2 className="font-bold text-xl mt-5">جمع المعلومات الشخصية</h2>

      <p className="text-justify mt-2">
        في كل زيارة لمنصة المستشار اللغوي التابع لمجمع الملك سلمان العالمي للغة العربية، يقوم الخادم الخاص بالمنصة
        بتسجيل عنوان إجراء شبكة الإنترنت IP الخاص بالمستخدم، ووقت الزيارة، والعنوان URL الخاص بأي منصة إلكترونية مُحيلة
        على منصة المستشار اللغوي التابع لمجمع الملك سلمان العالمي للغة العربية.{' '}
      </p>
      <p className="text-justify mt-2">
        تنشئ معظم المواقع الإلكترونية فور زيارتها ملفًا صغيرًا على القرص الصلب الخاص بجهاز الزائر (المتصفح)، ويسمى هذا
        الملف “كوكيز” (Cookies)، وملفات الكوكيز هي ملفات نصية تحتوي على معلومات تتيح للمنصة التي اختزنتها أن تسترجعها
        عند الحاجة إليها خلال زيارات المستخدم المقبلة للمنصة، ومن هذه المعلومات المحفوظة:{' '}
      </p>
      <p className="text-justify mt-2">تذكر اسم مستخدم وكلمة مرور.</p>
      <p className="text-justify mt-2">حفظ إعدادات الصفحة في حال إتاحتها على البوابة.</p>
      <p className="text-justify mt-2">
        قد لا يضطر المتصفح (الزائر) إلى إدخال كلمة المرور في كل زيارة؛ إذ سيتمكن نظام المنصة من اكتشافها عن طريق ملفات
        الكوكيز، أو قد تمنع المستخدم من تكرار عملية التصويت إذا كان قد قام بالتصويت سابقًا وهكذا. وعلى هذا الأساس فإن
        المنصة ستستخدم المعلومات الموجودة في ملفات الكوكيز لأغراض فنية خاصة بها، وذلك عند زيارته أكثر من مرة، كما أن
        المنصة بإمكانها تغيير المعلومات الموجودة ضمن ملفات الكوكيز أو إضافة معلومات جديدة كلما قمت بزيارة منصة المستشار
        اللغوي التابع لمجمع الملك سلمان العالمي للغة العربية.{' '}
      </p>
      <p className="text-justify mt-2">
        إذا استخدمت تطبيقًا مباشرًا أو أرسلت إلينا بريدًا إلكترونيًّا عبر المنصة الإلكترونية تزودنا فيه ببيانات شخصية،
        فإننا قد نشارك البيانات الضرورية مع جهات أو إدارات أخرى، وذلك لخدمتك بصورة أكثر فعالية. ولن نشارك بياناتك
        الشخصية مع الجهات غير الحكومية إلا إذا كانت مصرَّحًا لها من الجهات المختصة بأداء خدمات حكومية محددة. وبتقديمك
        لبياناتك ومعلوماتك الشخصية من خلال المنصة الإلكترونية، فإنك توافق تمامًا على تخزين تلك البيانات ومعالجتها
        واستخدامها من قبل السلطات السعودية. ونحن نحتفظ بالحق في كل الأوقات في كشف أي معلومات للجهات المختصة، عندما يكون
        ذلك ضروريًّا للالتزام بأي قانون أو نظام أو طلب حكومي.
      </p>
      <p className="text-justify mt-2">
        إنك مسؤول بمفردك عن كل ما يتعلق ببياناتك التي ترسلها عبر هذه المنصة، من تمام وصحة وصدق.
      </p>

      <h2 className="font-bold text-xl mt-5">حماية خصوصيتك</h2>

      <p className="text-justify mt-2">لكي نتمكن من مساعدتك في حماية معلوماتك الشخصية نوصيك بالآتي:</p>
      <p className="text-justify mt-2">
        - الاتصال الفوري بنا عندما تظن أن شخصًا ما استطاع الحصول على كلمة السر الخاصة بك، أو رمز الاستخدام، أو الرقم
        السري، أو أي معلومات سرية أخرى.
      </p>
      <p className="text-justify mt-2">
        لا تعط معلومات سرية عبر الهاتف أو شبكة الإنترنت ما لم تعرف هوية الشخص أو الطرف المستقبل للمعلومة.
      </p>
      <p className="text-justify mt-2">
        استخدم متصفحًا آمنًا عند إنجاز المعاملات عبر الإنترنت مع إغلاق التطبيقات غير المستخدمة على الشبكة، وتحقق من أن
        برنامج الحماية من الفيروسات محدّث على الدوام.
      </p>
      <p className="text-justify mt-2">
        في حالة الرغبة في أي استفسار أو تقديم رأي حول مبادئ الخصوصية، يمكنك التواصل مع إدارة المنصة عبر نموذج الاتصال في
        صفحة (اتصل بنا).
      </p>
      <p className="text-justify mt-2">
        للحفاظ على بياناتك الشخصية، نقوم بتأمين التخزين الإلكتروني والبيانات الشخصية المرسلة باستخدام التقنيات الأمنية
        المناسبة.
      </p>
      <p className="text-justify mt-2">
        هذه المنصة قد تحتوي على روابط إلكترونية لمواقع أو بوابات قد تستخدم طرقًا لحماية المعلومات وخصوصياتها تختلف عن
        الطرق المستخدمة لدينا، ونحن غير مسؤولين عن محتويات المواقع الأخرى وطرقها وخصوصياتها؛ لذا ننصحك بالرجوع إلى
        إشعارات الخصوصية الخاصة.
      </p>

      <h2 className="font-bold text-xl mt-5">إرسال الرسائل الإلكترونية</h2>
      <p className="text-justify mt-2">
        عند قيامك بالاستفسار أو الاستعلام حول خدمة محددة، أو في حال تزويدك المنصة بمعلومات إضافية مستخدمًا أيًّا من
        وسائل الاتصال مع مجمع الملك سلمان العالمي للغة العربية سواءٌ كانت إلكترونية أم غير إلكترونية، للاستفسار أو
        الاستشارة؛ فإننا سنستخدم عنوان بريدك الإلكتروني للرد على أسئلتك واستفساراتك، ومن الممكن حفظ عنوان بريدك ورسالتك
        وجوابنا عنها لغرض مراقبة الجودة، كما أننا قد نقوم بذلك لغايات قانونية ورقابية.
      </p>
      <h2 className="font-bold text-xl mt-5">الخصوصية</h2>
      <p className="text-justify mt-2">
        تضع منصة المستشار اللغوي التابعة لمجمع الملك سلمان العالمي للغة العربية (الذي يشار إليه هنا بعبارة «منصة
        المستشار اللغوي» أو «المنصة») سرية معلومات مستخدميه وزواره على رأس قائمة الأولويات، وتبذل إدارة المنصة كل جهودها
        لتقديم خدمة ذات جودة عالية لكل المستفيدين، وتُعدّ الخصوصية وسرية المعلومات الموضحة أدناه جزءًا من شروط استخدام
        المنصة وأحكامه. لا يجمع مجمع الملك سلمان العالمي للغة العربية معلوماتٍ شخصية عنك عندما تزور منصته إلا إذا اخترتَ
        أنت بمعرفتك تقديم هذه المعلومات لنا. إذا اخترتَ تقديم معلوماتك، فإننا لا نستخدمها إلا لإنجاز طلبك لتلقي معلومات
        أو خِدمات وَفق ما ورد في هذه السياسات؛ وباستخدامك للمنصة فإنك توافق على سياسة الخصوصية وسرية المعلومات هذه. يجب
        على زوار المنصة والمستفيدين من خدماتها الاطلاعُ المستمر على شروط الخصوصية وسرية المعلومات لمعرفة أي تحديثات تطرأ
        عليها؛ علمًا أن إدارة المنصة غير مطالبة بالإعلان عن أي تحديثات جديدة على تلك الشروط والمبادئ، ويعني استخدامك
        للمنصة اطلاعَك وموافقتك على تلك الشروط والمبادئ وما يجدُّ عليها من تعديلات مستمرة. مجمع الملك سلمان العالمي للغة
        العربية غير مسؤول تحت أي ظرف مهمَا كان عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو تبعية أو خاصة أو استثنائية
        -تنشأ عن استخدام هذه المنصة أو العجز عن استخدامه.
      </p>

      <h2 className="font-bold text-xl">سياسة استخدام المنصة:</h2>

      <p className="text-justify mt-2">
        يسعى مجمع الملك سلمان العالمي للغة العربية إلى أن يقدم خدماته في مجال الاستشارة اللغوية على نحو يوافق رؤية
        المجمع ومنهجه وأهدافه، وفقًا للأطر الآتية:
      </p>
      <ul>
        <li>
          <p className="text-justify mt-2">
            • تتيح المنصة لأي زائر الاستفادة من خزانة الاستشارات السابقة، ومن المكتبة الرقمية؛ كما تتيح له تقديم
            استشارته من أي مكان في العالم.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • مسؤولية التحقق من دقة مضمون الاستشارات الواردة وسلامتها تقع على المستشير، فهو المسؤول عما يرد فيها.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • تحرص منصة "المستشار اللغوي" على الإجابة عن أي استشارة لغوية واردة إليها في مدة عاجلة لا تتجاوز أربعًا
            وعشرين (24) ساعة من وقت ورود السؤال.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • يحق لإدارة منصة "المستشار اللغوي" استبعادُ أي استشارة مخالفة، أو عدمُ الإجابة عنها دون إبداء الأسباب.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • يحق لمنصة "المستشار اللغوي" الاستفادة من الاستشارات الواردة، ونشرُها، واستخدامُها استخدامًا مطلقًا، مع عدم
            الإشارة إلى أي معلومات شخصية لمقدم الاستشارة.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • لابد أن تكون الأسئلة والاستشارات مما يَحتاج إلى إجابة مباشرة، بمعنى ألا تكون أسئلة متصلة بموضوعات البحوث
            العلمية والتحقيقات وغيرها من المجالات البحثية.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • أن تكون الأسئلة والاستشارات ضمن حاجات الاستخدام، أي ليست أسئلة من باب اختبار القدرات، أو التحدي أو
            الألغاز، أو ترجيح أقوال علماء اللغة فيها.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • أن تكون الأسئلة والاستشارات خارجة عن مجالات التكليف العلمي (التقارير، الواجبات المدرسية والجامعية، التقويم
            والتحكيم ... إلخ).
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">• أن تكون الأسئلة والاستشارات واضحة.</p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • ألّا تحمل الاستشارة الواحدة طلبًا للحصول على أكثر من معلومة، مع إمكانية طرح سؤال آخر.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">• ألّا يتجاوز عدد الكلمات ستين كلمة في السؤال الواحد.</p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • أن يطرح المستفيد سؤاله مباشرة، دون مقدمات أو عبارات ترحيبية أو توديعية، حتى يستفيد أكبر استفادة من مساحة
            الحروف المخصَّصة له، وحتى يتاح إعادةُ نشر السؤال بطريقة مناسبة.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • يحق للمجمع تعديل سياسة استخدام منصة "المستشار اللغوي"، وحذفُ أي بند من بنودها أو تعديلُه دون استئذان طالب
            الاستشارة أو إشعاره.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • تمثل الإجابة عن السؤال أو الاستشارة رأي المستشار اللغوي، ولا تعكس بالضرورة رأي المجمع، ولا يتحمل المجمع
            ولا المستشار أيَّ مسؤولية قانونية مترتبة على المشورة.
          </p>
        </li>
        <li>
          <p className="text-justify mt-2">
            • إذا رغب المستفيد في طرح سؤاله، فعليه الموافقة على شروط المنصة وسياسة استخدامها.
          </p>
        </li>
      </ul>
    </Dialog>
  );
});

export default TermsAndConditionsDialog;
