import { Card } from 'primereact/card';
import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import academy_colored_logo from '../assets/images/academy_logo_inverted.png';
import academy_white_logo from '../assets/images/almustashar_allughawi_white_logo.png';
import login_image from '../assets/images/login_image.png';
import { LoginLayoutContext } from '../contexts/LoginLayoutContext';
import './LoginLayout.css';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/errorBoundary/ErrorBoundary';

const LoginLayout = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(login_image);

  return (
    <LoginLayoutContext.Provider value={{ title, setTitle, image, setImage }}>
      <div className="w" style={{ backgroundImage: `url(${image})` }}>
        <div className="w-container">
          <div className="w-media " style={{ backgroundImage: `url(${image})` }}>
            <div className="relative z-20 ">
              <Link to="/">
                <img src={academy_white_logo} alt="logo" className="h-[80px]" />
              </Link>
            </div>
          </div>
          <div className="w-card-container">
            <Card className="w-card">
              <div className="w-logo-d">
                <Link to="/">
                  <img src={academy_colored_logo} alt="logo" className="h-[80px]" />
                </Link>
              </div>
              <div className="w-title-container">
                <h1 className="w-title">{title}</h1>
                <span className="w-line"></span>
              </div>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                  navigate(pathname);
                }}>
                <Outlet />
              </ErrorBoundary>
            </Card>
          </div>
        </div>
      </div>
    </LoginLayoutContext.Provider>
  );
};

export default LoginLayout;
