import { useContext } from 'react';
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import icon_chat_outlined from '../../assets/icons/icon_chat_outlined.svg';
import icon_paper from '../../assets/icons/icon_paper.svg';
import icon_user from '../../assets/icons/icon_user.svg';
import almustashar_allughawi_white_logo from '../../assets/images/almustashar_allughawi_white_logo.png';
import home_page_image_1 from '../../assets/images/home_page_image_1.jpeg';
import home_page_image_2 from '../../assets/images/home_page_image_2.jpeg';
import home_page_image_3 from '../../assets/images/home_page_image_3.jpeg';
import ConsultantsSlider from '../../components/ConsultantsSlider';
import LCLinkButton from '../../components/form/LCLinkButton';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import QuestionsSlider from '../../components/QuestionsSlider';
import { UserContext } from '../../contexts/UserContext';
import { UserTypes } from '../../models/user';
import './home.css';

const Home = () => {
  const userContext = useContext(UserContext);

  if (userContext.isUserType([UserTypes.CONSULTANT])) {
    return <Navigate to="c" />;
  } else {
    return (
      <div className="grow">
        <div style={{ backgroundImage: `url(${home_page_image_1})`, backgroundSize: 'cover' }} className="">
          <NavigationBar menuBarStyle="transparent" />

          <div className="flex flex-col items-center w-full py-10 pb-20 h-container">
            {/* <img src={almustashar_allughawi_white_logo} className="h-60" alt="المستشار اللغوي" /> */}

            <p className="h-text text-3xl lg:text-6xl">طريقك إلى مزيد من المعرفة اللغوية</p>

            {/* <div className="flex gap-3">
              <div className="h-line"></div>
              TODO: Get links for the following buttons
              <a href='https://twitter.com/KSGAFAL'>
                <div className="h-btn-light">
                  <FaTwitter />
                </div>
              </a>
              <a href='https://facebook.com/KSGAAL'>
                <div className="h-btn-light">
                  <FaFacebookSquare />
                </div>
              </a>
              <a href=''>
                <div className="h-btn-light">
                  <FaLinkedin />
                </div>
              </a>
            </div> */}

            <LCLinkButton
              label="اطلب مشورة"
              to="new-question"
              className="flex inline-block  items-center justify-center  text-2xl lg:text-3xl lg:w-62 lg:h-16"
            />
          </div>
        </div>

        <div style={{ backgroundImage: `url(${home_page_image_2})`, backgroundSize: 'cover' }} className="h-about-c">
          <div className=" h-about-content">
            <h1 className="text-xl font-bold md:text-5xl font-header">
              <img src={icon_chat_outlined} alt="Icon" className="inline ml-5 h-8 lg:h-16" />
              حول المستشار اللغوي
            </h1>

            <p className="text2xl md:text-3xl">
              منصة لغوية إثرائية، تستقبل سؤالك، وتحيله على مختص لغوي، ثم تنشر الإجابة في المنصة، وتصل إلى بريدك
            </p>
          </div>
        </div>

        <div
          style={{ backgroundImage: `url(${home_page_image_3})`, backgroundSize: 'cover' }}
          className="h-consultant-cabinet">
          <div className="space-y-10">
            <div className="flex items-center justify-between">
              <h1 className="h-consultant-title">
                <img src={icon_paper} alt="Icon" className="inline ml-5 h-8 lg:h-16" />
                خزانة الاستشارات
              </h1>

              <LCLinkButton
                className="px-0 underline bg-transparent lg:bg-primary text-primary lg:text-white underline-offset-1 lg:no-underline lg:px-10 md:px-20"
                label="عرض الكل"
                to="bank"
              />
            </div>

            <QuestionsSlider />
          </div>
        </div>

        <div className="h-consultant">
          <div className="space-y-10">
            <div className="flex items-center justify-between">
              <h1 className="h-consultant-title">
                <img src={icon_user} alt="Icon" className="inline ml-5 h-8 lg:h-16" />
                هيئة المستشارين
              </h1>

              <LCLinkButton
                className="px-0 underline bg-transparent lg:bg-primary text-primary lg:text-white underline-offset-1 lg:no-underline lg:px-10 md:px-20"
                label="عرض الكل"
                to="consultants"
              />
            </div>

            <p className="text-2xl md:text-3xl">
              نخبة من المختصين والباحثين اللغويين يستقبلون الاستشارات اللغوية ويجيبون عنها وفقًا لآرائهم
            </p>

            <ConsultantsSlider />
          </div>
        </div>
      </div>
    );
  }
};

export default Home;
