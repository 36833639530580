import React, { Component } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { Link } from 'react-router-dom';

import './Questions.style.css';
interface Props {
  nextQuestionId: number;
  previousQuestionId: number;
  onClickItem: (id: number) => void;
}
const NextButtons = (props: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-s' }}>
      <div style={{ flex: 1 }} />

      {props.previousQuestionId && (
        <Link
          className="qLink"
          to={`/bank/${props.previousQuestionId}`}
          onClick={() => props.onClickItem(props.previousQuestionId)}
          title="الإستشارة السابقة">
          <FaArrowRight className="inline " />
          {/* <span className="hidden lg:inline"> السابقة</span> */}
        </Link>
      )}
      {props.nextQuestionId && (
        <Link
          to={`/bank/${props.nextQuestionId}`}
          onClick={() => props.onClickItem(props.nextQuestionId)}
          className="qLink"
          title="الإستشارة التالية">
          {/* <span style={{ flex: 1 }} className="hidden lg:inline">
            التالية
          </span> */}
          <FaArrowLeft className="inline " />
        </Link>
      )}
    </div>
  );
};
const style = {
  link: {
    background: '#004e28',
    color: 'white',
    width: 70,
    height: 30,
    fontSize: 20,
    margin: 5,
    // alignItems: 'center',
    borderRadius: 5,
  },
};

export default NextButtons;
