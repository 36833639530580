import { Field, Form, Formik } from 'formik';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import { UsersApi } from '../api/usersApi';
import icon_chat from '../assets/icons/icon_chat.svg';
import LCButton from '../components/form/LCButton';
import { PAGINATION_LIMIT } from '../models/pagination';
import { ListPublicConsultantsRequest, ListPublicConsultantsResponseItem } from '../models/usersApi';

const ConsultantsPublicList = () => {
  const [loading, setLoading] = useState(false);
  const [consultants, setConsultants] = useState<ListPublicConsultantsResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListPublicConsultantsRequest>({
    searchParam: '',
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  useEffect(() => {
    loadUsers();
  }, [request]);

  const loadUsers = () => {
    setLoading(true);
    UsersApi.listPublicConsultants(request).then(
      (res) => {
        setConsultants(consultants.concat(res.data.data));
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleOnLoadMore = () => {
    setRequest({ ...request, offset: request.offset + request.limit });
  };

  const shouldShowLoadMoreButton = () => consultants.length < total;

  return (
    <div className="flex flex-col gap-3 px-6 py-8 grow lg:px-16">
      <div className="flex items-center gap-5 text-4xl">
        <img src={icon_chat} alt="Icon" className="h-10" />
        <h1>هيئة المستشارين</h1>
      </div>

      <p className="text-xl">
        نخبة من المختصين والباحثين اللغويين يستقبلون الاستشارات اللغوية ويجيبون عنها وفقًا لآرائهم
      </p>
      <Formik
        initialValues={{ search: '' }}
        onSubmit={(values) => {
          setConsultants([]);
          setTotal(0);
          setRequest({ ...request, searchParam: values.search, offset: 0 });
        }}>
        {() => (
          <Form className="bg-[#f5f5f5] rounded-md flex w-full lg:w-2/3 mb-5 overflow-hidden justify-between">
            <Field
              type="text"
              name="search"
              placeholder="ابحث عن مستشار"
              className="w-[80%] h-full p-2 bg-transparent grow lg:w-auto"
              disabled={loading}
            />
            <button type="submit" disabled={loading} className="flex justify-end items-center w-[20%] px-2 lg:w-auto">
              {loading ? (
                <AiOutlineLoading3Quarters className="text-xl animate-spin" />
              ) : (
                <HiOutlineSearch className="text-xl" />
              )}
            </button>
          </Form>
        )}
      </Formik>

      {loading && !shouldShowLoadMoreButton() && <ProgressSpinner />}

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-5">
        {consultants.map((consultant, index) => (
          <div key={index} className="rounded-b-lg shadow-lg">
            <div className="h-2 rounded-t-lg bg-primaryLight"></div>
            <div className="p-5 space-y-3">
              <p className="text-sm font-bold lg:text-2xl">{consultant.name}</p>
              <p className="text-sm lg:text-xl">{consultant.position}</p>
            </div>
          </div>
        ))}
      </div>

      {shouldShowLoadMoreButton() && (
        <LCButton
          type="button"
          label="عرض المزيد"
          buttonStyle="roundedStyle1"
          className="self-center mt-5 md:px-20"
          onClick={handleOnLoadMore}
          loading={loading}
          disabled={loading}
        />
      )}
    </div>
  );
};

export default ConsultantsPublicList;
