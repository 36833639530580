import dayjs from 'dayjs';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { UsersApi } from '../../api/usersApi';
import refreh from '../../assets/icons/admin/refreh.png';
import userImg from '../../assets/icons/admin/usermange.png';
import { PAGINATION_LIMIT } from '../../models/pagination';
import icon_folders from '../../assets/icons/icon_folders.svg';
import { ListLatestUsersRequest, ListLatestUsersResponseItem } from '../../models/usersApi';
import { getFullName } from '../../utility/stringUtility';
import { userTypeMapper } from '../../utility/userTypeMapper';
import './dashboard.css';
import { DateRangeI } from '../../pages/Reports';
import EmptyStateImage from '../../assets/images/reports_empty_state_image.svg';

interface BoxUserProps {
  dateRange: DateRangeI;
  setTableLoading: (isLoading: boolean) => void;
}

const BoxUsers: React.FC<BoxUserProps> = ({ dateRange, setTableLoading }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ListLatestUsersResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListLatestUsersRequest>({
    offset: 0,
    limit: PAGINATION_LIMIT,
    to: dateRange.to,
    from: dateRange.from,
  });

  useEffect(() => {
    resetUsers();
  }, [dateRange]);

  useEffect(() => {
    loadUsers();
  }, [request]);

  useEffect(() => {
    setTableLoading(loading);
  }, [loading, setTableLoading]);

  const loadUsers = () => {
    setLoading(true);
    UsersApi.listLatestUsers(request).then(
      (res) => {
        setUsers(users.concat(res.data.data));
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const resetUsers = () => {
    setUsers([]);
    setTotal(0);
    setRequest((old) => {
      return { ...old, offset: 0, limit: PAGINATION_LIMIT, ...dateRange };
    });
  };

  const handleOnLoadMore = () => {
    setRequest((old) => {
      return { ...old, offset: old.offset + old.limit, limit: PAGINATION_LIMIT };
    });
  };

  const shouldShowLoadMoreButton = () => users.length < total;

  return (
    <Card className="d-t">
      <div className="flex items-center gap-2 pt-3 px-3">
        <div>
          <img src={icon_folders} alt="icon" className="h-8" />
        </div>
        <h2 className="d-c-title">تقرير المستخدمين الجدد </h2>
      </div>
      {users.length == 0 ? (
        <img src={EmptyStateImage} height="80vh" width="100%" />
      ) : (
        <table>
          <thead>
            <tr>
              <th>
                <div className="flex justify-center">
                  <img src={userImg} alt="icon" className="h-[25px]" />
                </div>
              </th>
              <th> نوع المستخدم</th>
              <th>
                <div className="flex justify-center">
                  <button onClick={resetUsers} disabled={loading}>
                    {!loading && <img src={refreh} alt="icon" className="h-[25px]" />}
                    {loading && <AiOutlineLoading3Quarters className="text-xl animate-spin" />}
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '200px' }}>
                    {user.firstName || user.lastName ? getFullName(user) : user.email}
                  </td>
                  <td>{userTypeMapper(user.userType?.name)}</td>
                  <td>{dayjs().locale('ar').to(user.createdAt)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {shouldShowLoadMoreButton() && (
        <button className="mt-4" onClick={handleOnLoadMore} disabled={loading}>
          {!loading && <span>عرض المزيد</span>}
          {loading && <AiOutlineLoading3Quarters className="text-xl animate-spin" />}
        </button>
      )}
    </Card>
  );
};

export default BoxUsers;
