import { Field } from 'formik';
import React from 'react';

interface LCRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  formik?: boolean
}

const LCRadioButton = ({ name, value, label, formik = true, ...props }: LCRadioButtonProps) => {
  return (
    <label className="flex items-center gap-1">

      {
        formik ? <Field
          type="radio"
          name={name}
          value={value}
          className="
          appearance-none w-4 h-4 rounded-full cursor-pointer
          border-2 border-white hover:bg-gray-100 outline outline-primary outline-offset-0 outline-1
          checked:bg-primary hover:checked:bg-green-700
        "
          {...props}
        /> :
          <input
            type="radio"
            name={name}
            value={value}
            className={`appearance-none w-4 h-4 rounded-full cursor-pointer
            border-2 border-white hover:bg-gray-100 outline outline-primary outline-offset-0 outline-1
            checked:bg-primary hover:checked:bg-green-700`}
            {...props}
          />
      }

      <span className="select-none">{label}</span>
    </label>
  );
};

export default LCRadioButton;
