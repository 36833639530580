import { UserContextValue } from './userContext';

export const UserContextProxy: UserContextValue = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  //authToken: null,
  userType: {
    name: null,
  },
  permissions: [],
  receiveQuestionsEnabled: null,
  position: null,
};
