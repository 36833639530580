import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { QuestionsApi } from '../../api/questionsApi';
import icon_folders from '../../assets/icons/icon_folders.svg';
import ConsultantContentCard from '../../components/ConsultantContentCard';
import LCButton from '../../components/form/LCButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCRadioButton from '../../components/form/LCRadioButton';
import HideQuestionComponent from '../../components/HideQuestionComponent';
import UserContentCard from '../../components/UserContentCard';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { UserContext } from '../../contexts/UserContext';
import { GetQuestionResponse, QuestionStatuses } from '../../models/questionsApi';
import { Permissions, UserTypes } from '../../models/user';
import { questionStatusMapper } from '../../utility/questionStatusMapper';
import { appendQuestionPrefix } from '../../utility/stringUtility';
import EditAnswerDialog, { EditAnswerDialogAttributes } from './EditAnswerDialog';
import ReassignQuestionDialog, { ReassignQuestionDialogAttributes } from './ReassignQuestionDialog';
import { convertNumsToArabic } from '../../helpers/number_converter';

const QuestionDetails = () => {
  let { id } = useParams();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState<GetQuestionResponse>(null);
  const reassignDialogRef = useRef<ReassignQuestionDialogAttributes>(null);
  const editAnswerDialogRef = useRef<EditAnswerDialogAttributes>(null);
  const layoutContext = useContext(AdminAndSupportLayoutContext);

  useEffect(() => {
    updateLayout();
    loadQuestion();
  }, []);

  const loadQuestion = () => {
    setLoading(true);
    QuestionsApi.getQuestion({ id: Number.parseInt(id) }).then(
      (res) => {
        const q = res.data.data;
        setQuestion(q);
        layoutContext.setTitle(q?.title == '' || q.title == null ? 'لايوجد عنوان للاستشارة' : q.title);
        layoutContext.setTitleAction(
          <HideQuestionComponent questionId={Number.parseInt(id)} initialHiddenState={q?.isHidden ?? false} />
        );
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateLayout = () => {
    layoutContext.setAll({
      title: 'عرض الاستشارة',
      titleIcon: <img src={icon_folders} alt="Icon" />,
      breadcrumbsTitle: 'عرض الاستشارة',
      breadcrumbs: [
        {
          label: 'إدارة الاستشارات',
          icon: <img src={icon_folders} alt="Icon" />,
          linkTo: 'questions',
        },
        {
          label: 'عرض الاستشارة',
        },
      ],
    });
  };

  const shouldShowReassignButton = () => {
    return (
      userContext.isUserType(UserTypes.ADMIN) &&
      (question.status === QuestionStatuses.NEW ||
        question.status === QuestionStatuses.ASSIGNED_TO_CONSULTANT ||
        question.status === QuestionStatuses.ANSWERED ||
        question.status === QuestionStatuses.ASSIGNED_TO_JUDGE)
    );
  };

  const shouldShowEditAnswerButton = () => {
    return (
      ((userContext.isUserType([UserTypes.ADMIN]) && question.status === QuestionStatuses.CERTIFIED) ||
        userContext.hasPermission(Permissions.EDIT_QUESTIONS)) &&
      !(question.status === QuestionStatuses.NEW && question.answers.length === 0)
    );
  };

  const getAnswerConsultantContentCardContentDate = () => {
    if (question.status === QuestionStatuses.REJECTED) {
      return question?.answers[0]?.updatedAt;
    } else {
      return question.answeredAt;
    }
  };

  return (
    <div>
      {loading && <ProgressSpinner />}
      {question && (
        <div className="space-y-5 mt-2 lg:mt-0">
          <span className="bg-primary text-white rounded-xl px-8 ml-1">{convertNumsToArabic(question.id)}</span>
          <span className="bg-primary text-white rounded-xl px-8">{questionStatusMapper(question.status).name}</span>
          <div className="order-1 lg:order-2">
            {question.category ? (
              <>
                <p className="text-xl">تصنيف الاستشارة:</p>
                <div className="flex gap-3">
                  <LCRadioButton
                    formik={false}
                    disabled
                    checked
                    label={question?.category?.name}
                    value={`${question?.category?.id}`}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="lg:flex gap-3 hidden">
              {question?.subCategory?.map((v, i) => (
                <LCCheckbox key={i} value={`${v?.id}`} formik={false} label={v?.name} checked disabled />
              ))}
            </div>
            {/* <div className="block lg:hidden mt-3">
              <LCRadioButton formik={false} disabled checked label={question?.category?.name} value={`${question?.category?.id}`} />
            </div> */}
            <div className="block lg:hidden mt-3">
              {question?.subCategory?.map((v, i) => (
                <LCCheckbox
                  key={i}
                  value={`${v?.id}`}
                  name="type"
                  id="type"
                  formik={false}
                  label={v?.name}
                  checked
                  disabled
                />
              ))}
            </div>
          </div>
          <UserContentCard
            date={question.createdAt}
            content={appendQuestionPrefix(question?.content)}
            backgroundColorClass="bg-[#f9f9f9]"
            dateStyle="dateAndTime"
          />

          {question.answers?.length > 0 && (
            <ConsultantContentCard
              consultantName={question?.answers[0]?.user}
              contentDate={getAnswerConsultantContentCardContentDate()}
              assignmentDate={question?.answers[0]?.createdAt}
              type="answer"
              status={question?.answers[0]?.status}
              content={question?.answers[0]?.adminContent || question?.answers[0]?.content}
              isContentChanged={!!question?.answers[0]?.adminContent}
              rejectionReason={question?.rejectionReason}
              backgroundColorClass="bg-[#f9f9f9]"
              dateStyle="dateAndTime"
            />
          )}

          {question.reviews?.length > 0 && (
            <ConsultantContentCard
              consultantName={question.reviews[0].user}
              contentDate={question.reviewedAt}
              assignmentDate={question.reviews[0].createdAt}
              type="review"
              status={question.reviews[0].status}
              content={question.reviews[0].judgeAnswer}
              backgroundColorClass="bg-[#f9f9f9]"
              dateStyle="dateAndTime"
              isAdmin={true}
            />
          )}

          <div className="flex flex-col lg:flex-row lg:justify-end gap-4 lg:gap-2 my-4 lg:my-0">
            {shouldShowReassignButton() && (
              <>
                <LCButton
                  label="إعادة إسناد الاستشارة"
                  buttonStyle="roundedStyle1"
                  onClick={() => reassignDialogRef.current.show()}
                />
                <ReassignQuestionDialog
                  ref={reassignDialogRef}
                  questionId={question?.id}
                  questionStatus={question.status}
                  questionReassignedCallback={() => loadQuestion()}
                />
              </>
            )}

            {shouldShowEditAnswerButton() && (
              <>
                <LCButton
                  label="تعديل الإجابة"
                  buttonStyle="roundedStyle1"
                  onClick={() => editAnswerDialogRef.current.show()}
                />
                <EditAnswerDialog
                  ref={editAnswerDialogRef}
                  questionTags={question?.tags}
                  questionContent={question?.content}
                  answerId={question?.answers[0]?.id}
                  originalContent={question.answers[0]?.content}
                  adminContent={question.answers[0]?.adminContent}
                  answerEditedCallback={() => loadQuestion()}
                  questionTitle={question.title}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionDetails;
