import React, { useImperativeHandle } from 'react';
import { QuestionsApi } from '../api/questionsApi';
import { lcConfirmPopup } from '../utility/lcConfirmPopup';

interface ReassignQuestionPopupProps {
  id: number;
  type: 'answer' | 'review';
  setLoading?: (loading: boolean) => void;
  successCallback?: () => void;
}

export interface ReassignQuestionPopupAttributes {
  show: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ReassignQuestionPopup = React.forwardRef<ReassignQuestionPopupAttributes, ReassignQuestionPopupProps>(
  ({ id, type, setLoading, successCallback }, ref) => {
    useImperativeHandle(ref, () => {
      return {
        show: (event: React.MouseEvent<HTMLButtonElement>) => showConfirmPopup(event),
      };
    });

    const api = type === 'answer' ? QuestionsApi.reassignAnswer : QuestionsApi.reassignReview;

    const showConfirmPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من إعادة إسناد هذه الاستشارة؟', () => {
        setLoading(true);
        api({ id }).then(
          () => {
            setLoading(false);
            if (successCallback) successCallback();
          },
          () => {
            setLoading(false);
          }
        );
      });
    };

    return <></>;
  }
);

export default ReassignQuestionPopup;
