import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { QuestionsApi } from '../api/questionsApi';
import icon_report from '../assets/icons/icon_report.svg';
import LCButton from '../components/form/LCButton';
import LCCalendar from '../components/form/LCCalendar';
import { AdminAndSupportLayoutContext } from '../contexts/AdminAndSupportLayoutContext';
import BoxUsers from '../components/Dashboard/BoxUsers';
import DailyConsultantsTable from '../components/Dashboard/DailyConsultantsTable';
import BoxConsultantsTable from '../components/Dashboard/BoxConsultantsTable';
import { UsersApi } from '../api/usersApi';
import './Reports.css';
import { FaDownload } from 'react-icons/fa';
import BoxLateAnswers from '../components/Dashboard/BoxLateAnswers';
export interface DateRangeI {
  from: string;
  to: string;
}

interface DateRangeType extends DateRangeI {
  type: string;
}

type HandleSubmit = ((values: DateRangeType, formikHelpers: FormikHelpers<DateRangeType>) => void | Promise<any>) &
  ((
    values: any,
    {
      setSubmitting,
    }: {
      setSubmitting: any;
    }
  ) => void);

const Reports = () => {
  const layoutContext = useContext(AdminAndSupportLayoutContext);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isReportExporting, setIsReportExporting] = useState<boolean>(false);
  const [contentType, setContentType] = useState('usersReport');

  useEffect(() => {
    layoutContext.setAll({ title: 'التقارير', titleIcon: <img src={icon_report} alt="Icon" /> });
  }, []);

  const getApiRequestBody = (from: Date, to: Date): DateRangeI => {
    return {
      from: from ? dayjs(from).format('YYYY-MM-DD') : dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
      to: to ? dayjs(to).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
    };
  };

  const [dateRange, setDateRange] = useState<DateRangeI>({ ...getApiRequestBody(null, null) });

  const downloadQuestionsReport = (dateRange: DateRangeI, setLoadingState: (submitting: boolean) => void) => {
    setLoadingState(true);
    QuestionsApi.downloadQuestionsReport(dateRange).then(
      (res) => {
        saveAs(res.data, `${Math.floor(Math.random() * 999)}تقرير الاستشارات اليومي.xlsx`);
        setLoadingState(false);
      },
      () => {
        setLoadingState(false);
      }
    );
  };
  const downloadLatestUsersReport = (dateRange: DateRangeI, setLoadingState: (submitting: boolean) => void) => {
    setLoadingState(true);
    UsersApi.downloadLatestUsersReport(dateRange).then(
      (res) => {
        saveAs(res.data, `${Math.floor(Math.random() * 999)} المستخدمين الجدد.xlsx`);
        setLoadingState(false);
      },
      () => {
        setLoadingState(false);
      }
    );
  };
  const downloadLateAnswerReport = (dateRange: DateRangeI, setLoadingState: (submitting: boolean) => void) => {
    setLoadingState(true);
    QuestionsApi.downloadLateAnswerReport(dateRange).then(
      (res) => {
        saveAs(res.data, `${Math.floor(Math.random() * 999)} الاستشارات المتأخرة .xlsx`);
        setLoadingState(false);
      },
      () => {
        setLoadingState(false);
      }
    );
  };

  const downloadConsultantsReport = (dateRange: DateRangeI, setLoadingState: (submitting: boolean) => void) => {
    setLoadingState(true);
    QuestionsApi.downloadConsultantsReport(dateRange).then(
      (res) => {
        saveAs(res.data, `${Math.floor(Math.random() * 999)}تقرير المستشارين.xlsx`);
        setLoadingState(false);
      },
      () => {
        setLoadingState(false);
      }
    );
  };

  const handleSubmit: HandleSubmit = (values, { setSubmitting }) => {
    const dateRange = getApiRequestBody(values.from, values.to);
    setDateRange(dateRange);
    setSubmitting(false);
  };

  const handleReporExporting = () => {
    if (contentType === 'dailyQuestionsReport') {
      downloadQuestionsReport(dateRange, setIsReportExporting);
    } else if (contentType === 'usersReport') {
      downloadLatestUsersReport(dateRange, setIsReportExporting);
    } else if (contentType === 'lateAnswerReport') {
      downloadLateAnswerReport(dateRange, setIsReportExporting);
    } else {
      downloadConsultantsReport(dateRange, setIsReportExporting);
    }
  };

  return (
    <div className="mt-4">
      <Formik
        initialValues={{ type: contentType, from: null, to: null }}
        validationSchema={Yup.object({
          type: Yup.string().required('إلزامي'),
        })}
        onSubmit={handleSubmit}>
        {({ isSubmitting, values, getFieldProps, handleChange }) => (
          <Form style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div className="flex flex-col gap-5 lg:flex-row items-center">
                <div>
                  <label htmlFor="from" className=" right-0 text-2xl left-auto text-black">
                    من
                  </label>
                  <LCCalendar
                    id="from"
                    name="from"
                    value={values.from}
                    maxDate={values.to}
                    onChange={handleChange}
                    withFloatingLabel={true}
                  />
                </div>

                <div>
                  <label htmlFor="to" className=" right-0 text-2xl left-auto text-black">
                    إلى
                  </label>
                  <LCCalendar
                    id="to"
                    name="to"
                    value={values.to}
                    minDate={values.from}
                    onChange={handleChange}
                    withFloatingLabel={true}
                  />
                </div>

                <LCButton
                  label="تحديث"
                  type="submit"
                  buttonStyle="roundedStyle1"
                  disabled={isSubmitting || isTableLoading || isReportExporting}
                  loading={isSubmitting || isTableLoading}
                  className="px-8 py-5 mt-8 w-full lg:w-auto"
                />
              </div>

              <div>
                <label htmlFor="type" className="right-0 text-2xl left-auto text-black">
                  نوع التقرير
                </label>

                <div className="buttons-container">
                  <div
                    className={`button ${contentType === 'dailyQuestionsReport' ? 'active' : ''}`}
                    onClick={() => setContentType('dailyQuestionsReport')}>
                    الاستشارات اليومية
                  </div>
                  <div
                    className={`button ${contentType === 'consultantsReport' ? 'active' : ''}`}
                    onClick={() => {
                      setContentType('consultantsReport');
                    }}>
                    المستشارين
                  </div>
                  <div
                    className={`button ${contentType === 'usersReport' ? 'active' : ''}`}
                    onClick={() => setContentType('usersReport')}>
                    المستخدمين
                  </div>
                  <div
                    className={`button ${contentType === 'lateAnswerReport' ? 'active' : ''}`}
                    onClick={() => setContentType('lateAnswerReport')}>
                    الاستشارات المتاخرة
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-5 lg:flex-row ">
              <LCButton
                label="تصدير البيانات"
                type="button"
                buttonStyle="roundedStyle1"
                disabled={isSubmitting || isTableLoading || isReportExporting}
                loading={isReportExporting}
                onClick={handleReporExporting}
                className="px-8 py-5 mt-6 w-full lg:w-auto"
                icon={<FaDownload className="inline ml-3" />}
              />
            </div>
          </Form>
        )}
      </Formik>
      {contentType === 'usersReport' && (
        <div className="flex flex-col gap-8 my-4 lg:flex-row">
          <BoxUsers dateRange={dateRange} setTableLoading={setTableLoading} />
        </div>
      )}
      {contentType === 'consultantsReport' && (
        <div className="flex flex-col gap-8 my-4 lg:flex-row">
          <BoxConsultantsTable dateRange={dateRange} setTableLoading={setTableLoading} />
        </div>
      )}
      {contentType === 'lateAnswerReport' && (
        <div className="flex flex-col gap-8 my-4 lg:flex-row">
          <BoxLateAnswers dateRange={dateRange} setTableLoading={setTableLoading} />
        </div>
      )}
      {contentType === 'dailyQuestionsReport' && (
        <div className="flex flex-col gap-8 my-4 lg:flex-row">
          <DailyConsultantsTable dateRange={dateRange} setTableLoading={setTableLoading} />
        </div>
      )}
    </div>
  );
};

export default Reports;
