import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaHashtag } from 'react-icons/fa';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { QuestionsApi } from '../../api/questionsApi';
import icon_book from '../../assets/icons/icon_book.svg';
import avatar_2 from '../../assets/images/avatar_2.png';
import ConsultantContentCard from '../../components/ConsultantContentCard';
import LCButton from '../../components/form/LCButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCDropdown from '../../components/form/LCDropdown';
import LCLinkButton from '../../components/form/LCLinkButton';
import LCRadioButton from '../../components/form/LCRadioButton';
import HideQuestionComponent from '../../components/HideQuestionComponent';
import LCSimpleMessage from '../../components/LCMessage';
import ReassignQuestionPopup, { ReassignQuestionPopupAttributes } from '../../components/ReassignQuestionPopup';
import TagsContentCard from '../../components/tagsContentCard';
import UserContentCard from '../../components/UserContentCard';
import { UserContext } from '../../contexts/UserContext';
import {
  AnswerStatuses,
  GetCategories,
  GetQuestionResponse,
  GetQuestionResponseAnswer,
  GetQuestionResponseReview,
  ListQuestionsBankRequest,
  ListQuestionsBankResponseItem,
  QuestionStatuses,
  ReviewStatuses,
  Tags,
} from '../../models/questionsApi';
import { Permissions } from '../../models/user';
import { appendConsultantPrefix, appendQuestionPrefix } from '../../utility/stringUtility';
import './questionsForAnswer.css';
import RejectQuestionDialog, { RejectQuestionDialogAttributes } from './RejectQuestionDialog';
import { MentionsInput, Mention } from 'react-mentions';
import { title } from 'process';
import { set } from 'date-fns';

const users = [
  {
    id: '1',
    display: 'استشارة 1',
    url: 'http://localhost:8080/',
  },
  {
    id: '2',
    display: 'استشارة 2',
    url: 'http://localhost:8080/',
  },
  {
    id: '3',
    display: 'استشارة 3',
    url: 'http://localhost:8080/',
  },
];

const QuestionsForAnswerDetails = () => {
  let { id } = useParams();
  const [standardVisible, setStandardVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [secLoading, setSecLoading] = useState(true);
  const [categories, setcategories] = useState<GetCategories[]>([]);
  const [category, setCategory] = useState<number>();
  const [subcategory, setSubCategory] = useState([]);
  const [questionTags, setQuestionsTags] = useState<Tags[]>([]);
  const [comment, setComment] = useState(' ');
  const [question, setQuestion] = useState<GetQuestionResponse>(null);
  const [questions, setQuestions] = useState<Array<{ id: number; display: string }>>(null);
  const [answer, setAnswer] = useState<GetQuestionResponseAnswer>(null);
  const [review, setReview] = useState<GetQuestionResponseReview>(null);
  const [submitted, setSubmitted] = useState(false);
  const [submitReason, setSubmitReason] = useState<'answer' | 'reject' | 'reassign'>(null);
  const [reassigningQuestion, setReassigningQuestion] = useState(false);
  const rejectQuestionDialogRef = useRef<RejectQuestionDialogAttributes>(null);
  const userContext = useContext(UserContext);
  const reassignQuestionPopupRef = useRef<ReassignQuestionPopupAttributes>(null);

  const ANSWER_CHARACTER_LIMIT = 500;
  const ANSWER_CHARACTER_WARNING_LIMIT = 400;

  const displayStandardVisible = (e: any) => {
    e.preventDefault();
    setStandardVisible(true);
  };

  useEffect(() => {
    QuestionsApi.getQuestion({ id: Number.parseInt(id) }).then(
      (res) => {
        const q = res.data.data;
        setQuestion(q);
        setAnswer(q.answers[0]);
        setComment(q?.answers[0]?.content === null ? '' : q?.answers[0]?.content);
        setReview(q.reviews[0]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
    getQuestionsList();
  }, []);

  useEffect(() => {
    QuestionsApi.getCategories().then(
      (res) => {
        setcategories(res.data.data);
        setSecLoading(false);
      },
      () => {
        setSecLoading(false);
      }
    );
    return () => {};
  }, []);

  useEffect(() => {
    categories.filter((v) => {
      if (v.id === category) {
        //data.push(...v.subCategory)
        setSubCategory([...subcategory, ...v.subCategory]);
      }
    });
    return () => {};
  }, [category]);
  const getQuestionsList = (request?: ListQuestionsBankRequest, setSubmitting?: (submitting: boolean) => void) => {
    const body: ListQuestionsBankRequest = request
      ? request
      : {
          searchParams: {
            term: '',
            from: null,
            to: null,
            types: [],
            categoryId: null,
            tagTerm: [],
            tag: false,
          },
        };

    QuestionsApi.listQuestionsBank(body).then(
      (res) => {
        // console.log(
        //   res.data.data.map((v) => {
        //     return { id: v.id, display: v.content };
        //   })
        // );
        setQuestions(
          res.data.data.map((v) => {
            return { id: v.id, display: v.content };
          })
        );
        //setLoading(false);
      },
      () => {
        //setLoading(false);
      }
    );
  };
  const questionEditable = () => {
    return (
      answer?.status === AnswerStatuses.ASSIGNED_TO_CONSULTANT ||
      answer.status === AnswerStatuses.RETURNED_TO_CONSULTANT
    );
  };
  const questionCanBeReassigned = () => {
    return answer?.status === AnswerStatuses.ASSIGNED_TO_CONSULTANT;
  };
  const shouldShowReview = () => {
    return review?.status === ReviewStatuses.CERTIFIED || review?.status === ReviewStatuses.RETURNED_TO_CONSULTANT;
  };
  const isHashtag = (value: string, keyCode: number, code: string): void => {
    const regexExp = /^#[^ !@#$%^&*(),.?":{}|<>]*$/gi;
    let tagWithoutSymb: Array<string>;

    if (keyCode === 32 || code === 'Space')
      value.split(' ').map((v, i) => {
        if (regexExp.test(v)) {
          tagWithoutSymb = v?.split('#');
          const filterTags = questionTags.filter((v) => v?.name == tagWithoutSymb[1]);
          if (filterTags?.length === 0 && v?.trim() !== '#')
            setQuestionsTags([...questionTags, { name: tagWithoutSymb[1] }]);
        }
      });
  };
  const shouldShowHideToggle = () => {
    return answer?.status === AnswerStatuses.ANSWERED;
  };

  const shouldShowRejectButton = () => {
    return questionEditable() && answer.status !== AnswerStatuses.RETURNED_TO_CONSULTANT;
  };

  const getAnswerConsultantContentCardContentDate = () => {
    if (question.status === QuestionStatuses.REJECTED) {
      return question.answers[0].updatedAt;
    } else {
      return question.answeredAt;
    }
  };

  const deleteQuestionTag = (index: number) => {
    const data = questionTags.filter((v, i) => i != index);
    setQuestionsTags(data);
  };

  const handleKeyDownChange = (e: any) => {
    if (e.keyCode === 13) {
      // alert('Enter key pressed' + e.keyCode);

      setComment(comment + '\n');
      // $PLACEHOLDER$
    }
  };
  const handleCommentChange = (e: any) => {
    // console.log(e.target.value);

    setComment(e.target.value);
  };
  return (
    <div className="grow px-4 lg:px-16 py-8">
      <div className="hidden lg:flex flex-wrap gap-3 items-center mb-5">
        <p className="text-adminBreadcrumbs font-bold text-2xl">عرض الاستشارة</p>
        <div className="bg-adminBreadcrumbs w-1 self-stretch"></div>

        <div className="flex gap-1 items-center">
          <div className="flex items-center">
            <img src={icon_book} alt="Icon" className="inline ml-2 h-5" />
            <Link to="/c" className="text-xl">
              قائمة الاستشارات
            </Link>
          </div>

          <HiOutlineChevronRight className="text-lg" />

          <Link to="/c" className="text-xl">
            استشارات مسندة
          </Link>

          <HiOutlineChevronRight className="text-lg" />

          <p className="text-xl">عرض الاستشارة</p>
        </div>
      </div>

      {loading && <ProgressSpinner />}
      {!loading && !submitted && (
        <>
          <Formik
            initialValues={{
              content: answer?.content || '',
              type: question.subCategory.map((v) => `${v.id}`) || [],
              hidden: false,
              isEasy: false,
              tags: '',
              title: '',
              categoryId: category || question.categoryId,
            }}
            validationSchema={Yup.object({
              content: Yup.string()
                .max(ANSWER_CHARACTER_LIMIT, `يجب ألا تتجاوز الإجابة ${ANSWER_CHARACTER_LIMIT} حرف`)
                .required('إلزامي'),
              type: Yup.array().min(1, 'يجب اخنيار تصنيف واحد على الاقل').required('إلزامي'),
              categoryId: Yup.string().nullable(),
              hidden: Yup.boolean(),
              isEasy: Yup.boolean(),
              tags: Yup.string(),
              title: Yup.string().required('يجب إدخال عنوان للاستشارة'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              // console.log(values.content);
              //const tags = values.tags.split("#").map(r => { if (r.length !== 0) return { name: r.replace(/\s+/g, '') } }).filter(isHashtag)
              const requests = [
                QuestionsApi.answerQuestion({
                  id: Number.parseInt(id),
                  content: values.content,
                  type: values.type,
                  isEasy: values.isEasy,
                  title: values.title,
                  categoryId: category,
                  tags: questionTags.length !== 0 ? questionTags : question?.tags,
                }),
              ];

              if (userContext.hasPermission(Permissions.HIDE_QUESTIONS)) {
                requests.push(
                  QuestionsApi.updateQuestionVisibility({ id: Number.parseInt(id), hidden: values.hidden })
                );
              }

              Promise.all(requests).then(
                () => {
                  setSubmitReason('answer');
                  setSubmitting(false);
                  setSubmitted(true);
                },
                () => {
                  setSubmitting(false);
                }
              );
            }}>
            {({ isSubmitting, values, handleChange }) => (
              <Form className="flex flex-col gap-5">
                <div className="flex flex-col gap-5">
                  <div className="order-2 lg:order-1 flex items-center justify-between">
                    <h1 className="text-primary text-3xl font-bold border-b-4 border-primary hidden lg:block">
                      {question.title}
                    </h1>

                    {/* Questions Options */}
                    {questionEditable() && (
                      <div className="flex flex-col gap-3">
                        <LCCheckbox
                          id="hidden"
                          name="hidden"
                          label="إخفاء الاستشارة"
                          formik={true}
                          disabled={!userContext.hasPermission(Permissions.HIDE_QUESTIONS)}
                        />
                        {!question.answers[0].user.isTeam ? (
                          <LCCheckbox
                            id="isEasy"
                            name="isEasy"
                            label="استشارة بسيطة"
                            formik={true}
                            //disabled={!userContext.hasPermission(Permissions.HIDE_QUESTIONS)}
                          />
                        ) : null}

                        <LCButton
                          label="إعادة إسناد"
                          type="button"
                          onClick={(e) => reassignQuestionPopupRef.current?.show(e)}
                          disabled={reassigningQuestion}
                          loading={reassigningQuestion}
                          className="block lg:hidden  text-black !px-4 w-auto bg-[#eee]"
                        />
                      </div>
                    )}
                    {shouldShowHideToggle() && (
                      <HideQuestionComponent questionId={Number.parseInt(id)} initialHiddenState={question.isHidden} />
                    )}
                  </div>
                  {/* End Questions Options */}

                  {/* Categories and subCategories */}
                  {questionEditable() && question.status != QuestionStatuses.RETURNED_TO_CONSULTANT && (
                    <div className="order-1 lg:order-2">
                      <p className="text-xl">تصنيف الاستشارة:</p>
                      <div className="lg:flex gap-3 hidden">
                        {categories.map((v, i) => {
                          return (
                            <LCRadioButton
                              checked={category == v.id}
                              key={i}
                              id={`${i}`}
                              name="categoryId"
                              label={v.name}
                              value={`${v.id}`}
                              onChange={() => {
                                setCategory(v.id);
                                setSubCategory([]);
                                values.type.splice(0, values.type.length);
                              }}
                            />
                          );
                        })}
                        <ErrorMessage name="categoryId" className="text-red-600" component="p" />
                      </div>

                      {/* =============== Mobile =========== */}
                      <div className="block lg:hidden mt-3">
                        <LCDropdown
                          options={categories.map((v, i) => {
                            return { label: v.name, value: v.id };
                          })}
                          value={category}
                          placeholder="اختر تصنيف الاستشارة"
                          onChange={(e) => {
                            setCategory(+e.target.value);
                            setSubCategory([]);
                            values.type.splice(0, values.type.length);
                          }}
                          id="categoryId"
                          name="categoryId"
                          inputClassName="py-0 bg-inputBackground w-full"
                        />
                        <ErrorMessage name="categoryId" className="text-red-600" component="p" />
                      </div>
                      {/* ===========SubCategory============= */}
                      <div className="block lg:hidden mt-3">
                        <p className="text-xl mt-2">تصنيف الاستشارة الفرعية:</p>

                        {subcategory.length > 0 && (
                          <MultiSelect
                            options={subcategory.map((v, i) => {
                              return { label: v.name, value: v.id };
                            })}
                            value={values.type}
                            onChange={handleChange}
                            inputId="type"
                            name="type"
                            className="py-0 bg-inputBackground w-full"
                            panelHeaderTemplate={<></>}
                          />
                        )}
                        <ErrorMessage name="type" className="text-red-600" component="p" />
                      </div>

                      <p className={`${subcategory.length === 0 ? `hidden` : ``} text-xl mt-1`}>
                        تصنيف الاستشارة الفرعية:
                      </p>

                      <div className="lg:flex gap-3 hidden">
                        {subcategory.map((v, i) => (
                          <LCCheckbox
                            key={i}
                            value={`${v?.id}`}
                            name="type"
                            id="type"
                            formik={true}
                            label={v?.name}
                            //disabled={!editMode}
                          />
                        ))}
                        <ErrorMessage name="type" className="text-red-600" component="p" />
                      </div>
                    </div>
                  )}
                  {question.status == QuestionStatuses.RETURNED_TO_CONSULTANT || !questionEditable() ? (
                    <div className="order-1 lg:order-2">
                      <p className="text-xl">تصنيف الاستشارة:</p>
                      <div className="lg:flex gap-3 hidden">
                        <LCRadioButton
                          disabled
                          checked
                          label={question?.category?.name}
                          value={`${question.categoryId}`}
                        />
                      </div>
                      <div className="lg:flex gap-3 hidden">
                        {question?.subCategory?.map((v, i) => (
                          <LCCheckbox
                            key={i}
                            value={`${v?.id}`}
                            name="type"
                            id="type"
                            formik={true}
                            label={v?.name}
                            checked
                            disabled
                          />
                        ))}
                      </div>
                      <div className="block lg:hidden mt-3">
                        <LCRadioButton
                          name="categoryId"
                          disabled
                          checked
                          label={question?.category?.name}
                          value={`${question?.category?.id}`}
                        />
                      </div>
                      <div className="block lg:hidden mt-3">
                        {question?.subCategory?.map((v, i) => (
                          <LCCheckbox
                            key={i}
                            value={`${v?.id}`}
                            name="type"
                            id="type"
                            formik={true}
                            label={v?.name}
                            checked
                            disabled
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* End Categories and subCategories */}

                <h1 className="lg:hidden text-primary text-xl font-bold border-b-4 border-primary self-start">
                  {question.title}
                </h1>

                <UserContentCard
                  date={question.createdAt}
                  content={appendQuestionPrefix(question.content)}
                  backgroundColorClass="bg-[#f9f9f9]"
                />

                {!questionEditable() && (
                  <>
                    <ConsultantContentCard
                      consultantName={userContext}
                      contentDate={getAnswerConsultantContentCardContentDate()}
                      type="answer"
                      status={question.answers[0].status}
                      content={answer.adminContent || answer.content}
                      isContentChanged={!!answer.adminContent}
                      rejectionReason={question.rejectionReason}
                      backgroundColorClass="bg-[#f9f9f9]"
                    />
                  </>
                )}
                {question.status == QuestionStatuses.RETURNED_TO_CONSULTANT || !questionEditable() ? (
                  <TagsContentCard tags={question.tags} />
                ) : (
                  <></>
                )}

                {shouldShowReview() && (
                  <ConsultantContentCard
                    consultantName={review.user}
                    contentDate={question.reviewedAt}
                    type="review"
                    status={question.reviews[0].status}
                    content={question.reviews[0].judgeAnswer}
                    backgroundColorClass="bg-[#f9f9f9]"
                  />
                )}
                <p className="text-xl">عنوان الاستشارة:</p>

                {questionEditable() && (
                  <div className="flex gap-2 col-start-1 lg:col-start-2">
                    <div className="grow">
                      <Field
                        id="title"
                        name="title"
                        as="textarea"
                        className="bg-[#f9f9f9] rounded-xl w-full p-4 font-bold text-[#1d9bf0]"
                        rows="1"
                      />
                    </div>
                  </div>
                )}
                <ErrorMessage name="title" className="text-red-600" component="p" />

                {questionEditable() && (
                  <>
                    <div className="grid grid-cols-1-form lg:grid-cols-2-form lg:gap-x-4 gap-y-2">
                      <img src={avatar_2} alt="Consultant avatar" className="w-8 h-8 hidden lg:block" />

                      <div className="flex items-center gap-2">
                        <div className="block lg:hidden">
                          <img src={avatar_2} alt="Consultant avatar" className="w-8 h-8 " />
                        </div>
                        <p className="text-xl font-bold">{appendConsultantPrefix(userContext)}</p>
                        <AiFillInfoCircle
                          className="inline-block  lg:hidden cursor-pointer"
                          onClick={(e) => displayStandardVisible(e)}
                        />
                        <AiFillInfoCircle className="hidden lg:inline-block" />
                      </div>

                      <div className="flex gap-2 col-start-1 lg:col-start-2">
                        <div className="grow">
                          <MentionsInput
                            value={comment}
                            name="content"
                            style={style}
                            id="content"
                            rows={5}
                            dir="rtl"
                            onKeyDown={handleKeyDownChange}
                            onChange={(e) => {
                              handleCommentChange(e);
                              values.content = e.target.value;
                            }}>
                            <Mention
                              markup="@__id__"
                              trigger="@"
                              data={questions}
                              style={{ backgroundColor: '#cee4e5' }}
                            />
                          </MentionsInput>

                          {/* <Field
                            id="content"
                            name="content"
                            as="textarea"
                            className="bg-[#f9f9f9] rounded-xl w-full p-4"
                            placeholder="يمكنك كتابة الرد هنا"
                            rows="5"
                      
                          /> */}
                          {values.content.length >= ANSWER_CHARACTER_WARNING_LIMIT && (
                            <p>طول الإجابة {`${values.content.length} / ${ANSWER_CHARACTER_LIMIT}`} حرف</p>
                          )}
                          <ErrorMessage name="content" className="text-red-600" component="p" />
                        </div>

                        <div className="hidden lg:block">
                          <div className="flex items-center gap-2">
                            <AiFillInfoCircle className="inline-block" />
                            <p className="font-bold">يرجى الالتزام بمعايير الإجابة</p>
                          </div>
                          <ul>
                            <li>
                              لابد أن تتسم بروح التسامح، والتعاطي مع الناس بمختلف فئاتهم بدرجة عالية من العطاء، والرغبة
                              في الإفادة.
                            </li>
                            <li>أن تكون الإجابة مباشرة.</li>
                            <li>ألا يتجاوز زمن الإجابة على الاستشارة 24 ساعة من لحظة إنشاء الاستشارة.</li>
                            <li><strong>مراجعة الاستشارات السابقة ذات العلاقة بالاستشارة الجديدة قبل الإجابة عليها</strong></li>
                            <li>ألا تتجاوز الإجابة {ANSWER_CHARACTER_LIMIT} حرف.</li>
                            <li>أن تشتمل الإجابة على توضيح مبسط لما يمكن أن يلتبس في حال تغير الموضع و الدلالة.</li>
                            <li>إعطاء رأي عام يتفق عليه الجميع ولا يكون محل خلاف.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {questionEditable() && question.status != QuestionStatuses.RETURNED_TO_CONSULTANT && (
                  <div className="grid grid-cols-1-form lg:grid-cols-2-form lg:gap-x-4 gap-y-2">
                    <div className="flex items-center gap-2">
                      <p className="text-xl font-bold">وسم الاستشارة </p>
                      <FaHashtag className="hidden lg:inline-block" />
                    </div>
                    <div className="flex gap-2 col-start-1 items-center lg:col-start-2">
                      <div className="grow">
                        <Field
                          id="tags"
                          name="tags"
                          as="textarea"
                          onKeyUp={(e: any) => {
                            isHashtag(e.target.value, e.keyCode, e.code);
                            values.tags = '';
                          }}
                          className="bg-[#f9f9f9] rounded-xl w-full p-4 font-bold text-[#1d9bf0]"
                          rows="1"
                        />
                        <div className="bg-[#f9f9f9] flex flex-row w-full p-4 font-bold text-white">
                          {questionTags.map((v, i) => (
                            <div className="rounded-full flex  hover:cursor-pointer flex-row justify-between bg-primary opacity-80 p-1 m-1">
                              <div onClick={() => deleteQuestionTag(i)} className="w-3 border-l ml-2">
                                x
                              </div>
                              {v.name}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="hidden lg:block">
                        <div className="flex items-center gap-2">
                          <AiFillInfoCircle className="inline-block" />
                          <p className="font-bold">يرجى الالتزام بمعايير التصنيفات</p>
                        </div>
                        <ul>
                          <li>يجب كتابة رمز # قبل اي تصنيف</li>
                          <li>اذا كان التصنيف مكون من كلمتين او اكثر يجب فصلهم بهذا الرمز _</li>
                          <li>يجب ترك مسافة بين التصنيفات</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {questionEditable() && (
                  <div className="flex justify-between lg:justify-center gap-5">
                    {shouldShowRejectButton() && (
                      <LCButton
                        label="الاعتذار عن الإجابة"
                        type="button"
                        onClick={() => rejectQuestionDialogRef.current.show()}
                      />
                    )}
                    <LCButton label="إرسال الإجابة" type="submit" disabled={isSubmitting} loading={isSubmitting} />
                    {questionCanBeReassigned() && (
                      <LCButton
                        label="إعادة إسناد"
                        type="button"
                        onClick={(e) => reassignQuestionPopupRef.current?.show(e)}
                        disabled={reassigningQuestion}
                        loading={reassigningQuestion}
                        className="hidden lg:block"
                      />
                    )}
                  </div>
                )}

                <ReassignQuestionPopup
                  ref={reassignQuestionPopupRef}
                  id={Number.parseInt(id)}
                  type="answer"
                  setLoading={setReassigningQuestion}
                  successCallback={() => {
                    setSubmitReason('reassign');
                    setSubmitted(true);
                  }}
                />

                <RejectQuestionDialog
                  ref={rejectQuestionDialogRef}
                  id={Number.parseInt(id)}
                  isJudge={false}
                  questionRejectedCallback={() => {
                    setSubmitReason('reject');
                    setSubmitted(true);
                  }}
                />

                <Dialog
                  header={<span className="h-1 w-20 block bg-black rounded-md mx-auto"></span>}
                  className="m-0  font-main"
                  contentClassName="text-lg"
                  resizable={false}
                  draggable={false}
                  dismissableMask={true}
                  visible={standardVisible}
                  position={'bottom'}
                  onHide={() => setStandardVisible(false)}>
                  <div className="q-f-a-s">
                    <div className="flex items-center gap-2">
                      <AiFillInfoCircle className="inline-block" />
                      <p className="font-bold">يرجى الالتزام بمعايير الإجابة</p>
                    </div>
                    <ul>
                      <li>
                        لابد أن تتسم بروح التسامح، والتعاطي مع الناس بمختلف فئاتهم بدرجة عالية من العطاء، والرغبة في
                        الإفادة.
                      </li>
                      <li>أن تكون الإجابة مباشرة.</li>
                      <li>ألا يتجاوز زمن الإجابة على الاستشارة 24 ساعة من لحظة إنشاء الاستشارة.</li>
                      <li>ألا تتجاوز الإجابة {ANSWER_CHARACTER_LIMIT} حرف.</li>
                      <li>أن تشتمل الإجابة على توضيح مبسط لما يمكن أن يلتبس في حال تغير الموضع و الدلالة.</li>
                      <li>إعطاء رأي عام يتفق عليه الجميع ولا يكون محل خلاف.</li>
                    </ul>
                  </div>
                </Dialog>
              </Form>
            )}
          </Formik>
        </>
      )}
      {submitted && (
        <div>
          {
            {
              answer: <LCSimpleMessage label="أرسلت الإجابة بنجاح" type="success"></LCSimpleMessage>,
              reject: <LCSimpleMessage label="أرسل الاعتذار للمستشير" type="success"></LCSimpleMessage>,
              reassign: <LCSimpleMessage label="أعيد إسناد الاستشارة بنجاح" type="success"></LCSimpleMessage>,
            }[submitReason]
          }

          <LCLinkButton label="عودة إلى قائمة الاستشارات" to="/c" className="inline-block mt-5" />
        </div>
      )}
    </div>
  );
};
const style = {
  control: {
    backgroundColor: '#f9f9f9',
    fontSize: 14,
    borderRadius: 20,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 170,
    },
    highlighter: {
      padding: 9,
      //border: '1px solid transparent',
    },
    input: {
      padding: 9,
      //border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
export default QuestionsForAnswerDetails;
