import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { AnswerApi } from '../../api/answerApi';
import { QuestionsApi } from '../../api/questionsApi';
import LCButton from '../../components/form/LCButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCDropdown from '../../components/form/LCDropdown';
import LCRadioButton from '../../components/form/LCRadioButton';
import LCSimpleMessage from '../../components/LCMessage';
import * as Yup from 'yup';
import { GetCategories, GetQuestionResponse, ListQuestionsBankRequest } from '../../models/questionsApi';
const users = [
  {
    id: '1',
    display: 'استشارة 1',
    url: 'http://localhost:8080/',
  },
  {
    id: '2',
    display: 'استشارة 2',
    url: 'http://localhost:8080/',
  },
  {
    id: '3',
    display: 'استشارة 3',
    url: 'http://localhost:8080/',
  },
];
export interface EditAnswerDialogAttributes {
  show: () => void;
}
interface EditAnswerDialogProps {
  answerId: number;
  originalContent: string;
  adminContent: string;
  questionTitle?: string;
  questionContent: GetQuestionResponse;
  answerEditedCallback: () => void;
  editAnswer: boolean;
  isChangedByJudge?: boolean;
}

const EditAnswerDialogReview = React.forwardRef<EditAnswerDialogAttributes, EditAnswerDialogProps>(
  (
    {
      answerId,
      originalContent,
      adminContent,
      answerEditedCallback,
      questionTitle,
      questionContent,
      editAnswer,
      isChangedByJudge,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [answerEdited, setAnswerEdited] = useState(false);
    const [questionTitleState, setQuestionTitle] = useState(questionTitle);
    const [questions, setQuestions] = useState<Array<{ id: number; display: string }>>(null);
    const [comment, setComment] = useState(editAnswer ? originalContent : '');
    const [secLoading, setSecLoading] = useState(true);
    const [categories, setcategories] = useState<GetCategories[]>([]);
    const [category, setCategory] = useState<number>(questionContent.categoryId);
    const [subcategory, setSubCategory] = useState([]);
    const ANSWER_CHARACTER_LIMIT = 500;
    const ANSWER_CHARACTER_WARNING_LIMIT = 400;
    useImperativeHandle(ref, () => {
      return {
        show: () => setVisible(true),
      };
    });
    useEffect(() => {
      setComment(editAnswer ? originalContent : '');
    }, [editAnswer]);
    useEffect(() => {
      setAnswerEdited(false);
    }, [visible]);

    useEffect(() => {
      QuestionsApi.getCategories().then(
        (res) => {
          //console.log(res.data.data)
          setcategories(res.data.data);
          setSecLoading(false);
        },
        () => {
          setSecLoading(false);
        }
      );
      return () => {};
    }, []);

    useEffect(() => {
      categories.filter((v) => {
        if (v.id === category) {
          // console.log(category);
          //data.push(...v.subCategory)
          setSubCategory([...subcategory, ...v.subCategory]);
        }
      });
      getQuestionsList();
      return () => {};
    }, [category, questionContent.categoryId, categories]);

    const getQuestionsList = (request?: ListQuestionsBankRequest, setSubmitting?: (submitting: boolean) => void) => {
      const body: ListQuestionsBankRequest = request
        ? request
        : {
            searchParams: {
              term: '',
              from: null,
              to: null,
              types: [],
              categoryId: null,
              tagTerm: [],
              tag: false,
            },
          };

      QuestionsApi.listQuestionsBank(body).then(
        (res) => {
          // console.log(
          //   res.data.data.map((v) => {
          //     return { id: v.id, display: v.title };
          //   })
          // );
          setQuestions(
            res.data.data.map((v) => {
              return { id: v.id, display: v.title };
            })
          );
          //setLoading(false);
        },
        () => {
          //setLoading(false);
        }
      );
    };

    const getSubmitButtonLabel = (newAdminContent: string) => {
      if (adminContent && !newAdminContent) {
        return 'مسح';
      } else {
        return 'تعديل';
      }
    };
    const handleKeyDownChange = (e: any) => {
      if (e.keyCode === 13) {
        // alert('Enter key pressed' + e.keyCode);

        setComment(comment + '\n');
        // $PLACEHOLDER$
      }
    };
    const handleCommentChange = (e: any) => {
      //console.log(e.target.value)
      setComment(e.target.value);
    };
    return (
      <Dialog
        header={
          <div className="flex w-3/5 gap-2">
            <h1 className="text-3xl font-bold">تعديل السؤال</h1>
            <div className="h-1 grow bg-black relative self-end bottom-3"></div>
          </div>
        }
        closable={true}
        className="m-5 md:w-1/2 font-main"
        contentClassName="text-lg"
        resizable={false}
        draggable={false}
        dismissableMask={true}
        visible={visible}
        onHide={() => setVisible(false)}>
        {!answerEdited ? (
          <Formik
            initialValues={{
              judgeAnswer: '',
              questionTitle: '',
              type: questionContent.subCategory.map((v) => `${v.id}`) || [],
              categoryId: category,
            }}
            validationSchema={Yup.object({
              judgeAnswer: Yup.string()
                .max(ANSWER_CHARACTER_LIMIT, `يجب ألا تتجاوز الإجابة ${ANSWER_CHARACTER_LIMIT} حرف`)
                .required('إلزامي'),
              type: Yup.array().min(1, 'يجب اخنيار تصنيف واحد على الاقل').required('إلزامي'),
              categoryId: Yup.string().nullable(),
              hidden: Yup.boolean(),
              isEasy: Yup.boolean(),
              tags: Yup.string(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              // console.log(values, category);
              // alert(isChangedByJudge);
              // return;
              QuestionsApi.returnQuestionToConsultant({
                id: questionContent.id,
                judgeAnswer: values.judgeAnswer,
                categoryId: category,
                type: values.type,
                changedByJudge: isChangedByJudge,
              }).then(
                () => {
                  setSubmitting(false);
                  answerEditedCallback();
                  setVisible(false);
                },
                () => {
                  setSubmitting(false);
                }
              );
              // AnswerApi.updateAdminContent({ id: answerId, adminContent: values.adminContent, questionTitle: values.questionTitle, type: values.type, categoryId: category }).then(
              //   () => {
              //     setSubmitting(false);
              //     setAnswerEdited(true);
              //     answerEditedCallback();
              //   },
              //   () => {
              //     setSubmitting(false);
              //   }
              // );
            }}>
            {({ isSubmitting, values, handleChange }) => (
              <Form className="flex flex-col">
                {!secLoading && (
                  <>
                    <p className="text-xl">تصنيف الاستشارةالاصلي :</p>
                    <div className="lg:flex gap-3 hidden">
                      <LCRadioButton
                        disabled
                        checked
                        label={questionContent.category.name}
                        value={`${questionContent.categoryId}`}
                      />
                    </div>
                    <div className="lg:flex gap-3 hidden">
                      {questionContent?.subCategory?.map((v, i) => (
                        <LCCheckbox
                          key={i}
                          value={`${v?.id}`}
                          name="_"
                          id="_"
                          formik={true}
                          label={v?.name}
                          checked
                          disabled
                        />
                      ))}
                    </div>
                    <div className="block lg:hidden mt-3">
                      <LCRadioButton
                        name="_"
                        disabled
                        checked
                        label={questionContent.category.name}
                        value={`${questionContent.category.id}`}
                      />
                    </div>
                    <div className="block lg:hidden mt-3">
                      {questionContent?.subCategory?.map((v, i) => (
                        <LCCheckbox
                          key={i}
                          value={`${v?.id}`}
                          name="_"
                          id="_"
                          formik={false}
                          label={v?.name}
                          checked
                          disabled
                        />
                      ))}
                    </div>
                  </>
                )}
                <strong className="mt-4">عنوان الاستشارة</strong>
                <Field
                  id="questionTitle"
                  name="questionTitle"
                  // onChange={(e: any) => setQuestionTitle(e.target.value)}
                  value={questionTitleState}
                  as="textarea"
                  rows="1"
                  disabled
                  className="bg-[#f9f9f9] rounded-md p-1"
                />

                <strong>الإجابة الأصلية:</strong>
                <p className="bg-[#f9f9f9] rounded-md p-1">{originalContent}</p>

                <>
                  {' '}
                  <p className="text-xl">تصنيف الاستشارة:</p>
                  <div className="lg:flex gap-3 hidden">
                    {categories.map((v, i) => {
                      return (
                        <LCRadioButton
                          checked={category == v.id}
                          key={i}
                          id={`${i}`}
                          name="categoryId"
                          label={v.name}
                          value={`${v.id}`}
                          onChange={() => {
                            setCategory(v.id);
                            setSubCategory([]);
                            values.type.splice(0, values.type.length);
                          }}
                        />
                      );
                    })}
                    <ErrorMessage name="categoryId" className="text-red-600" component="p" />
                  </div>
                  <div className="lg:flex gap-3 hidden">
                    {subcategory.map((v, i) => (
                      <LCCheckbox
                        key={i}
                        value={`${v?.id}`}
                        name="type"
                        id="type"
                        formik={true}
                        label={v?.name}
                        //disabled={!editMode}
                      />
                    ))}
                    <ErrorMessage name="type" className="text-red-600" component="p" />
                  </div>
                  {/* Mobile  */}
                  <div className="block lg:hidden mt-3">
                    <LCDropdown
                      options={categories.map((v, i) => {
                        return { label: v.name, value: v.id };
                      })}
                      value={category}
                      placeholder="اختر تصنيف الاستشارة"
                      onChange={(e) => {
                        setCategory(+e.target.value);
                        setSubCategory([]);
                        values.type.splice(0, values.type.length);
                      }}
                      id="categoryId"
                      name="categoryId"
                      inputClassName="py-0 bg-inputBackground w-full"
                    />
                  </div>
                  <div className="block lg:hidden mt-3">
                    <p className="text-xl mt-2">تصنيف الاستشارة الفرعية:</p>

                    {subcategory.length > 0 && (
                      <MultiSelect
                        options={subcategory.map((v, i) => {
                          return { label: v.name, value: v.id };
                        })}
                        value={values.type}
                        onChange={handleChange}
                        inputId="type"
                        name="type"
                        className="py-0 bg-inputBackground w-full"
                        panelHeaderTemplate={<></>}
                      />
                    )}
                  </div>
                </>
                <strong className="mt-4">الإجابة المعدلة:</strong>
                {/* <Field
                  id="adminContent"
                  name="adminContent"
                  as="textarea"
                  rows="5"
                  className="bg-[#f9f9f9] rounded-md p-1"
                /> */}
                <div>
                  <MentionsInput
                    value={comment}
                    name="judgeAnswer"
                    id="judgeAnswer"
                    style={{ backgroundColor: '#f9f9f9', borderRadius: 10, minHeight: 63 }}
                    onKeyDown={(e) => {
                      handleKeyDownChange(e);
                    }}
                    onChange={(e) => {
                      handleCommentChange(e);
                      values.judgeAnswer = e.target.value;
                    }}>
                    <Mention markup="@__id__" trigger="@" data={questions} style={{ backgroundColor: '#cee4e5' }} />
                  </MentionsInput>
                  {values.judgeAnswer.length >= ANSWER_CHARACTER_WARNING_LIMIT && (
                    <p>طول الإجابة {`${values.judgeAnswer.length} / ${ANSWER_CHARACTER_LIMIT}`} حرف</p>
                  )}
                  <ErrorMessage name="judgeAnswer" className="text-red-600" component="p" />
                </div>
                <div className="flex flex-row-reverse gap-2 mt-6">
                  <LCButton
                    label="إلغاء"
                    type="button"
                    buttonStyle="roundedStyle1Outlined"
                    onClick={() => setVisible(false)}
                  />
                  <LCButton
                    label={getSubmitButtonLabel(values.judgeAnswer)}
                    type="submit"
                    buttonStyle="roundedStyle1"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <LCSimpleMessage type="success" label="تم تعديل الإجابة بنجاح" />
            <LCButton
              label="إغلاق"
              type="button"
              buttonStyle="roundedStyle1Outlined"
              className="float-left"
              onClick={() => setVisible(false)}
            />
          </>
        )}
      </Dialog>
    );
  }
);

export default EditAnswerDialogReview;
