import { ErrorMessage } from 'formik';
import { Calendar, CalendarProps } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import './LCCalendar.css';

interface LCCalendarProps extends CalendarProps {
  withFloatingLabel?: boolean;
  floatingLabel?: string;
}

const LCCalendar = ({ value, onChange, id, name, withFloatingLabel, floatingLabel, ...props }: LCCalendarProps) => {
  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1.5 }}
      />
    );
  };

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="mr-2"
        style={{ lineHeight: 1.5 }}
      />
    );
  };

  const dateTemplate = (date: { day: number; month: number; selectable: boolean; today: boolean; year: number }) => {
    const date2 = new Date(date.year, date.month, date.day);

    if (date2.getDay() === 5 || date2.getDay() === 6) {
      return (
        // Set background color in style to avoid tailwind marking it with !important,
        // because we want the selected color to have higher priority
        <span className="w-full h-full flex justify-center items-center" style={{ backgroundColor: 'rgb(248 250 252)', color: "grey" }}>
          {date.day}
        </span>
      );
    } else {
      return date.day;
    }
  };

  return (
    <div>
      {withFloatingLabel ? (
        <span className="p-float-label">
          <Calendar
            value={value}
            onChange={onChange}
            monthNavigator
            yearNavigator
            yearRange="2022:2030"
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            dateTemplate={dateTemplate}
            showButtonBar={true}
            showIcon
            iconPos="left"
            id={id}
            inputId={id}
            {...props}
          />
          <label htmlFor={id || name} className="pb-4 right-0 text-2xl left-auto text-black">
            {floatingLabel}
          </label>
        </span>
      ) : (
        <Calendar
          value={value}
          onChange={onChange}
          monthNavigator
          yearNavigator
          yearRange="2022:2030"
          monthNavigatorTemplate={monthNavigatorTemplate}
          yearNavigatorTemplate={yearNavigatorTemplate}
          dateTemplate={dateTemplate}
          showButtonBar={true}
          showIcon
          iconPos="left"
          id={id}
          inputId={id}
          {...props}
        />
      )}
      {name && <ErrorMessage name={name} className="text-red-600 inline-block" component="span" />}
    </div>
  );
};

export default LCCalendar;
