import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { UsersApi } from '../api/usersApi';
import { ListPublicConsultantsResponseItem } from '../models/usersApi';
import CardAdvisoryBoard from './CardAdvisoryBoard/CardAdvisoryBoard';
import Carousel from './Carousel/Carousel';

const ConsultantsSlider = () => {
  const [loading, setLoading] = useState(false);
  const [consultants, setConsultants] = useState<ListPublicConsultantsResponseItem[]>([]);

  useEffect(() => {
    loadConsultants();
  }, []);

  const loadConsultants = () => {
    setLoading(true);
    UsersApi.listPublicConsultants({ offset: 0, limit: 10 }).then(
      (res) => {
        setConsultants(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return loading ? <ProgressSpinner /> : <Carousel items={consultants} Component={CardAdvisoryBoard} />;
};

export default ConsultantsSlider;
