import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useState } from 'react';
import { QuestionsApi } from '../api/questionsApi';
import { UserContext } from '../contexts/UserContext';
import { Permissions } from '../models/user';
import LCCheckbox from './form/LCCheckbox';

interface HideQuestionComponentProps {
  questionId: number;
  initialHiddenState: boolean;
}

const HideQuestionComponent = ({ questionId, initialHiddenState }: HideQuestionComponentProps) => {
  const userContext = useContext(UserContext);
  const [hidden, setHidden] = useState(initialHiddenState);
  const [loading, setLoading] = useState(false);

  const updateQuestionVisibility = (id: number, hidden: boolean) => {
    setLoading(true);
    QuestionsApi.updateQuestionVisibility({ id, hidden }).then(
      () => {
        setHidden(hidden);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="flex gap-3 items-center">
      <span className="text-lg">إخفاء الاستشارة</span>
      {loading ? (
        <ProgressSpinner strokeWidth="0.5rem" className="w-5 h-5 m-0" />
      ) : (
        <LCCheckbox
          checked={hidden}
          onChange={(e) => updateQuestionVisibility(questionId, e.target.checked)}
          disabled={!userContext.hasPermission(Permissions.HIDE_QUESTIONS)}
        />
      )}
    </div>
  );
};

export default HideQuestionComponent;
