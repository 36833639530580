import React from 'react';
import { useNavigate } from 'react-router';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="px-4 py-20 flex flex-col gap-4 justify-center items-center">
      <h1 className="text-2xl">404</h1>
      <h2 className="text-2xl">الصفحة غير موجودة</h2>
      <button
        className="w-auto inline-block bg-primary text-white text-2xl rounded-md p-2"
        onClick={() => navigate('/')}>
        عودة إلى الصفحة الرئيسة
      </button>
    </div>
  );
};

export default NotFound;
