import { UserTypes } from '../models/user';

const userTypeMap = {
  GUEST: 'زائر',
  USER: 'مستخدم',
  CONSULTANT: 'مستشار',
  SUPPORT: 'دعم فني',
  ADMIN: 'مسؤول النظام',
};

export const userTypeMapper = (userType: UserTypes) => userTypeMap[userType];
