import React, { useEffect } from 'react';
import './style.css';
import { Tooltip } from 'primereact/tooltip';
import { v4 as uuidv4 } from 'uuid';

type ChartRowProps = {
  title: string;
  percent: number;
  tooltip: string;
};

type ProgressTypes = '' | 'success' | 'error' | 'darkGreen' | 'warning';

const ChartRow: React.FC<ChartRowProps> = ({ title, percent, tooltip }) => {
  const [id, setId] = React.useState('');
  const [progressType, setProgressType] = React.useState<ProgressTypes>('');
  useEffect(() => {
    setId(uuidv4());
  }, []);

  useEffect(() => {
    if (percent > 80) {
      setProgressType('darkGreen');
    } else if (percent < 80 && percent > 60) {
      setProgressType('success');
    } else if (percent < 60 && percent > 40) {
      setProgressType('warning');
    } else {
      setProgressType('error');
    }
  }, [percent]);

  return (
    <div className="chart-row flex justify-between w-full">
      <div className="title">{title}</div>
      {/* <Tooltip target={`#a${id}`} position="left" className="!fill-white lg:!fill-black "> */}
      <div id={`#a${id}`} className="progress" title={tooltip}>
        <div className={`bg ${progressType}`}></div>
        <div className={`value ${progressType}`} style={{ width: `${percent}%` }}></div>
      </div>
      {/* </Tooltip> */}
      <div className={`percent ${progressType}`}>{`${percent}%`}</div>
    </div>
  );
};

export default ChartRow;
