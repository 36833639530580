import React, { useState } from 'react';
import ChartRow from '.';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import EmptyStateImage from '../../../assets/images/emptyState.svg';
import DateRangeDialog, { DialogDateRange } from '../../Dashboard/DateRangeDialog';

type DateItem = {
  title: string;
  percent: number;
  tooltip: string;
};

type ChartTableProps = {
  headerTitle: string;
  data: DateItem[];
  onChangeDate: (data: DialogDateRange) => void;
  isLoading?: boolean;
};
const ChartTable: React.FC<ChartTableProps> = ({ headerTitle, data, onChangeDate, isLoading }) => {
  const [dateRange, setdateRange] = useState<DialogDateRange>({ from: null, to: null });

  return (
    <div className="chartTable">
      <div className="header">
        <div className="title">{headerTitle}</div>
        <DateRangeDialog
          dateRange={dateRange}
          handleSubmit={(dateRange: DialogDateRange) => {
            setdateRange(dateRange);
            onChangeDate(dateRange);
          }}
        />
      </div>
      {isLoading ? (
        <div className="center-chart-content">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className="top">
            <div className="title"> النوع</div>
            <div className="number">العدد</div>
            <div className="percent">النسبة</div>
          </div>
          <div className="content">
            {data?.length ? (
              data.map((item) => <ChartRow title={item.title} percent={item.percent} tooltip={item.tooltip} />)
            ) : (
              <div className="center-chart-content">
                <img src={EmptyStateImage} alt="empty section" />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ChartTable;
