import React, { useContext, useEffect } from 'react';
import LinkBtn from '../../components/button/LinkBtn';
import LCSimpleMessage from '../../components/LCMessage';
import { LoginLayoutContext } from '../../contexts/LoginLayoutContext';

const RegisterSuccess = () => {
  const loginLayoutContext = useContext(LoginLayoutContext);

  useEffect(() => {
    loginLayoutContext.setTitle('التحقق من الحساب');
  }, []);

  return (
    <>
      <LCSimpleMessage type="success" label="تم إكمال عملية التسجيل بنجاح" className="mb-3" />
      <LinkBtn href="/">الصفحة الرئيسة</LinkBtn>
    </>
  );
};

export default RegisterSuccess;
